import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import queueManagementAvgCashRegisterOpen from '../../web_api/methods/queueManagementDash/avgCashRegisterOpen';
import { setValue as setAvgTimeCashRegisterAllLocations } from '../../redux/avgTimeCashRegisterAllLocationsReducer';
import { setValue as setQueueDurationAllLocations } from '../../redux/queueDurationAllLocationsReducer';
import { setValue as setAvgTimeCashRegisterOpenList } from '../../redux/avgTimeCashRegisterOpenListReducer';
import { setValue as setQueueDurationList } from '../../redux/queueDurationListReducer';
import { ChartsWidget15 } from '../../_metronic/widgets/charts/ChartsWidget15';
import { locationsList, userLocationList } from '../../web_api/models';
import getLocations from '../../web_api/methods/settings/locations/getLocations';
import { getTimeInExactMinutes, getTimeInMinutes, numberToTimeFormat } from '../../web_api/methods/helpers';
import { defaultAvgCashRegisterOpen, demoLocationsList } from './demoAvgCashRegisterOpen';

type Props = {
  className: string;
  from: Date;
  to: Date;
};

export type GroupedArray = {
  waitingTime: number;
  count: any;
  locations: any;
}[];

const AvgTimesQueueManagement: React.FC<Props> = ({ className, from, to }) => {
  const [values, setValues] = useState<number[]>([]);
  const [secondaryValues, setSecondaryValues] = useState<number[]>([]);
  const [names, setNames] = useState<string[]>([]);
  const [loaded, setLoaded] = useState(false);
  const [oneLocationSelected, setOneLocationSelected] = useState(false);
  const cachedLocationList = useSelector((state: RootState) => state.cachedLocations);

  const activeLocation = useSelector((state: RootState) => state.activeLocation);
  const selectedUser = useSelector((state: RootState) => state.selectedUser);
  const dateFilter = useSelector((state: RootState) => state.dateFilter);
  const hourParameters = useSelector((state: RootState) => state.hourParameters);
  const userList = useSelector((state: RootState) => state.userList);
  const username = useSelector((state: RootState) => state.user.user_name);

  const [orderByType, setOrderByType] = useState('');
  const [timeGroupSelected, setTimeGroupSelected] = useState(0);
  const [timeGroupAvailable, setTimeGroupAvailable] = useState<number[]>([]);
  const [groupedArray, setGroupedArray] = useState<GroupedArray>([]);
  const [newValues, setNewValues] = useState<number[]>([]);
  const [newSecondaryValues, setNewSecondaryValues] = useState<number[]>([]);
  const [newNames, setNewNames] = useState<string[]>([]);

  const dispatch = useDispatch();

  const isDemoUser = () => {
    console.log("username.includes('demo')", username.includes('demo'));
    return username.includes('demo');
  };

  const loadAudioAlerts = async () => {
    try {
      const fromDate = new Date(dateFilter.from);
      const toDate = new Date(dateFilter.to);

      const daysList = [];
      let currentDate = fromDate;
      while (currentDate <= toDate) {
        const dateString = `${currentDate.getFullYear()}-${(currentDate.getMonth() + 1).toString().padStart(2, '0')}-${currentDate
          .getDate()
          .toString()
          .padStart(2, '0')}T00:00:00`;
        daysList.push(dateString);
        currentDate.setDate(currentDate.getDate() + 1);
      }

      let loadedLocations: locationsList | userLocationList = [];
      if (isDemoUser()) {
        loadedLocations = demoLocationsList;
      } else if (selectedUser.id === '0') {
        loadedLocations = cachedLocationList.list.length === 0 ? await getLocations() : cachedLocationList.list;
      } else loadedLocations = userList.list.find((x) => x.id === selectedUser.id)?.locations!;
      let forValues = new Array();
      let forSecondaryValues = new Array();
      let forNames = new Array();
      let saveAvgTimesListInStore = new Array();
      let saveQueueDurationListInStore = new Array();
      let avgSum = 0;
      let queueDurationSum = 0;
      let avgTimeInMinutes = 0;
      let avgTimeInExactMinutes = 0;
      let queueDurationInMinutes = 0;
      let queueDurationInExactMinutes = 0;
      if (activeLocation.id === '0') {
        setOneLocationSelected(false);
        let avgTimesList = !isDemoUser()
          ? await queueManagementAvgCashRegisterOpen(0, new Date(dateFilter.from), new Date(dateFilter.to), selectedUser.id)
          : defaultAvgCashRegisterOpen;
        avgTimesList.forEach((avgTimes) => {
          let avgCashRegisterOpen = avgTimes.avg_time;
          let queueDuration = avgTimes.queue_duration;
          console.log({ avgCashRegisterOpen, queueDuration });
          console.log({ loadedLocations });
          if (loadedLocations.filter((x) => x.id === avgTimes.location_id).length > 0) {
            console.log('here');
            if (avgCashRegisterOpen !== undefined && avgCashRegisterOpen !== null && avgCashRegisterOpen !== 'No data') {
              avgTimeInMinutes = getTimeInMinutes(avgCashRegisterOpen);
              avgTimeInExactMinutes = getTimeInExactMinutes(avgCashRegisterOpen);
              forValues.push(avgTimeInMinutes);
              saveAvgTimesListInStore.push({
                avgTime: avgCashRegisterOpen !== 'No data' ? avgTimeInExactMinutes : 0,
                name: loadedLocations.find((x) => x.id === avgTimes.location_id)!.name,
                id: avgTimes.location_id,
              });
            } else forValues.push(0);
            if (queueDuration !== undefined && queueDuration !== null && queueDuration !== 'No data') {
              queueDurationInMinutes = getTimeInMinutes(queueDuration);
              queueDurationInExactMinutes = getTimeInExactMinutes(queueDuration);
              forSecondaryValues.push(queueDurationInMinutes);
              saveQueueDurationListInStore.push({
                queue_duration: queueDuration !== 'No data' ? queueDurationInExactMinutes : 0,
                name: loadedLocations.find((x) => x.id === avgTimes.location_id)!.name,
                id: avgTimes.location_id,
              });
            } else forSecondaryValues.push(0);
            forNames.push(avgTimes.location_id);
            if (avgTimeInExactMinutes !== undefined && avgTimeInExactMinutes !== null && !isNaN(avgTimeInExactMinutes))
              avgSum += avgTimeInExactMinutes;
            if (queueDurationInExactMinutes !== undefined && queueDurationInExactMinutes !== null && !isNaN(queueDurationInExactMinutes))
              queueDurationSum += queueDurationInExactMinutes;
          }
        });
        let minutes = avgSum / loadedLocations.length; // input value
        let avgTimeCashRegisterAllLocations = numberToTimeFormat(minutes);
        let minutesQueueDuration = queueDurationSum / loadedLocations.length; // input value
        let queueDurationAllLocations = numberToTimeFormat(minutesQueueDuration);

        dispatch(
          setAvgTimeCashRegisterAllLocations({
            value: avgTimeCashRegisterAllLocations,
          })
        );
        dispatch(
          setQueueDurationAllLocations({
            value: queueDurationAllLocations,
          })
        );
        dispatch(
          setAvgTimeCashRegisterOpenList({
            list: saveAvgTimesListInStore,
          })
        );
        dispatch(
          setQueueDurationList({
            list: saveQueueDurationListInStore,
          })
        );
      } else {
        setOneLocationSelected(true);
        for (let i = 0; i < daysList.length; i++) {
          const date = new Date(daysList[i]);
          const day = date.getDate();
          const month = date.toLocaleString('default', { month: 'short' });
          const formattedDate = `${day} ${month}`;
          forNames.push(formattedDate);
          let avgTimes = !isDemoUser()
            ? await queueManagementAvgCashRegisterOpen(parseInt(activeLocation.id), date, date, selectedUser.id)
            : defaultAvgCashRegisterOpen;
          let avgCashRegisterOpen = avgTimes[0].avg_time;
          let queueDuration = avgTimes[0].queue_duration;
          const avgTimeInMinutes = getTimeInMinutes(avgCashRegisterOpen);
          const queueDurationInMinutes = getTimeInMinutes(queueDuration);
          const avgTimeInExactMinutes = getTimeInExactMinutes(avgCashRegisterOpen);
          const queueDurationInExactMinutes = getTimeInExactMinutes(queueDuration);
          forValues.push(avgTimeInMinutes);
          forSecondaryValues.push(queueDurationInMinutes);
          dispatch(
            setAvgTimeCashRegisterOpenList({
              list: [
                {
                  avgTime: avgTimeInExactMinutes,
                  id: avgTimes[0].location_id,
                  name: loadedLocations.find((x) => x.id === avgTimes[0].location_id)!.name,
                },
              ],
            })
          );
          dispatch(
            setQueueDurationList({
              list: [
                {
                  queue_duration: queueDurationInExactMinutes,
                  id: avgTimes[0].location_id,
                  name: loadedLocations.find((x) => x.id === avgTimes[0].location_id)!.name,
                },
              ],
            })
          );
        }
      }
      console.log({ forNames, forValues, forSecondaryValues });

      setLoaded(true);
      setValues(forValues);
      setSecondaryValues(forSecondaryValues);
      setNames(forNames);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    setLoaded(false);
    loadAudioAlerts();
  }, [hourParameters, activeLocation, dateFilter.from, dateFilter.to, selectedUser.id]);

  useEffect(() => {
    const combinedArray = names.map((location, index) => ({
      location: location,
      waitingTime: orderByType === 'Queue duration' ? secondaryValues[index] : values[index],
    }));

    // Step 2: Group by waiting time and count locations
    const waitingTimeMap = combinedArray.reduce((acc: any, item) => {
      if (acc[item.waitingTime]) {
        acc[item.waitingTime].count += 1;
        acc[item.waitingTime].locations.push(item.location);
      } else {
        acc[item.waitingTime] = { count: 1, locations: [item.location] };
      }
      return acc;
    }, {});

    // Step 3: Convert the map to an array of the desired format
    const groupedArray = Object.entries(waitingTimeMap).map(([waitingTime, data]: [string, any]) => ({
      waitingTime: parseInt(waitingTime),
      count: data.count,
      locations: data.locations,
    }));

    // Optional: Sort the grouped array by waiting time
    groupedArray.sort((a, b) => a.waitingTime - b.waitingTime);
    setGroupedArray(groupedArray);

    setTimeGroupAvailable(groupedArray.map((item) => item.waitingTime));
  }, [names, orderByType, secondaryValues, values]);

  useEffect(() => {
    if (groupedArray?.length > 0 && timeGroupSelected > 0) {
      const newXAxis = names.filter((item) => groupedArray.filter((g) => g.waitingTime === timeGroupSelected)[0].locations.includes(item));
      const newXAxisIndexes = newXAxis.map((item) => {
        return names.findIndex((x) => x === item);
      });
      const newYAxis1 = values.filter((_, index) => newXAxisIndexes.includes(index));
      const newYAxis2 = secondaryValues.filter((_, index) => newXAxisIndexes.includes(index));

      setNewNames(newXAxis);
      setNewValues(newYAxis1);
      setNewSecondaryValues(newYAxis2);
    }
  }, [timeGroupSelected]);

  console.log({ names, values });
  console.log({ defaultAvgCashRegisterOpen });

  return timeGroupSelected > 0 ? (
    <ChartsWidget15
      className={className}
      xAxis={newNames}
      yAxis1={newValues}
      yAxis2={newSecondaryValues}
      loaded={loaded}
      oneLocationSelected={oneLocationSelected}
      orderByType={orderByType}
      setOrderByType={setOrderByType}
      setTimeGroupSelected={setTimeGroupSelected}
      timeGroupAvailable={timeGroupAvailable}
      timeGroupSelected={timeGroupSelected}
      groupedArray={groupedArray}
    />
  ) : (
    <ChartsWidget15
      className={className}
      xAxis={names}
      yAxis1={values}
      yAxis2={secondaryValues}
      loaded={loaded}
      oneLocationSelected={oneLocationSelected}
      orderByType={orderByType}
      setOrderByType={setOrderByType}
      setTimeGroupSelected={setTimeGroupSelected}
      timeGroupAvailable={timeGroupAvailable}
      timeGroupSelected={timeGroupSelected}
      groupedArray={groupedArray}
    />
  );
};
export { AvgTimesQueueManagement };

import callApiAxiosClient from '../../../axios/axios_client';
import { locationSchedule } from '../../../models';

function getLocationSchedule(locationId: number): Promise<locationSchedule | null> {
  const token = JSON.parse(localStorage.getItem('user')!);
  const authorization = 'Bearer ' + token.token;
  return callApiAxiosClient<locationSchedule | null>({
    method: 'get',
    url: 'settings/locations/schedule-by-location',
    headers: {
      'Content-Type': 'Application/json',
      Authorization: authorization,
    },
    params: {
      locationId: locationId,
    },
  }).then((res) => {
    if (res) return res;
    return null;
  });
}
export default getLocationSchedule;

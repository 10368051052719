/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef, useState } from 'react';
import getUsers from '../../../web_api/methods/account/getUsers';
import { locationsList, userItemInList, userItemList, userLocationList } from '../../../web_api/models';
import * as ReactBootstrap from 'react-bootstrap';
import addUser from '../../../web_api/methods/account/addUser';
import deleteUser from '../../../web_api/methods/account/deleteUser';
import { useDispatch, useSelector } from 'react-redux';
import { setValue } from '../../../redux/activeLocationReducer';
import { setValue as setUserList } from '../../../redux/userListReducer';
import deleteUserLocation from '../../../web_api/methods/account/deleteUserLocation';
import addUserLocation from '../../../web_api/methods/account/addUserLocation';
import { RootState } from '../../../redux/store';
import useToolbarSetter from '../../../hooks/Common/useToolbarSetter';
import resetPasswordUser from '../../../web_api/methods/account/resetPasswordUser';
import { ToastContainer, toast } from 'react-toastify';
import getRoleId from '../../../web_api/methods/account/getRoleId';
import { Col, Form, Row } from 'react-bootstrap';
import updateRoleId from '../../../web_api/methods/account/updateRoleId';

type Props = {
  className: string;
  locationsOnUserParam: locationsList;
};

enum RoleChange {
  Add = 1,
  Substract = -1,
}

const TablesWidget1: React.FC<Props> = ({ className, locationsOnUserParam }) => {
  const dispatch = useDispatch();
  const { setToolbar } = useToolbarSetter();
  const [users, setUsers] = useState<userItemList>([]);
  const [show, setShow] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [showAddLocation, setShowAddLocation] = useState(false);
  const [showDeleteUser, setShowDeleteUser] = useState(false);
  const [showResetPasswordUser, setShowResetPasswordUser] = useState(false);
  const [showRemoveLocation, setShowRemoveLocation] = useState(false);
  const [showDeleteChecked, setShowDeleteChecked] = useState(false);
  const [showEditRole, setShowEditRole] = useState(false);
  const [activeUser, setActiveUser] = useState<string>('');
  const [userToRemoveLocation, setUserToRemoveLocation] = useState<string>('');
  const [userToAddLocation, setUserToAddLocation] = useState<string>('');
  const [locationToRemove, setLocationToRemove] = useState<number>(0);
  const [levelSelection, setLevelSelection] = useState<number>(1);
  const [createUserAddLocation, setCreateUserAddLocation] = useState<number[]>([]);
  const [userToDelete, setUserToDelete] = useState<string>('');
  const [userToReset, setUserToReset] = useState<string>('');
  const [usersToDelete, setUsersToDelete] = useState<string[]>([]);
  const [locationList, setLocationList] = useState<userLocationList>([]);
  const [hasError, setHasError] = useState<boolean>(false);
  const [roleId, setRoleId] = useState<number>();
  const [selectedUser, setSelectedUser] = useState<userItemInList>();
  const emailInputValue = useRef<HTMLInputElement>(null);
  const passwordInputValue = useRef<HTMLInputElement>(null);
  const confirmPasswordInputValue = useRef<HTMLInputElement>(null);
  const [locationSearchValue, setLocationSearchValue] = useState('');
  const [locationsOnUser, setLocationsOnUser] = useState<locationsList>(locationsOnUserParam);
  const activeLocation = useSelector((state: RootState) => state.activeLocation);
  const hourParameters = useSelector((state: RootState) => state.hourParameters);

  function handleCheckboxChange(event: any, id: string) {
    var list = usersToDelete;
    if (event.target.checked) {
      if (list.find((x) => x === id) === undefined) {
        list.push(id);
      }
    } else {
      list = list.filter((userId) => userId !== id);
    }
    setUsersToDelete([...list]);
  }
  const handleCloseModal = () => {
    setShow(false);
    setShowEdit(false);
    setShowDeleteUser(false);
    setShowRemoveLocation(false);
    setShowDeleteChecked(false);
    setShowAddLocation(false);
    setShowResetPasswordUser(false);
    setShowEditRole(false);
    setCreateUserAddLocation([]);
    setLocationsOnUser(locationsOnUserParam);
    setLocationSearchValue('');
  };

  const handleShowModal = () => setShow(true);
  const handleShowModalDeleteLocation = (user_id: string, location_id: number) => {
    setShowRemoveLocation(true);
    var user = users.find((x) => x.id === user_id)!.name;
    setActiveUser(user);
    setUserToRemoveLocation(user_id);
    setLocationToRemove(location_id);
  };
  const handleShowModalEdit = (id: string) => {
    var user = users.find((x) => x.id === id);
    setActiveUser(user?.name + ' - Level' + user?.role_id);
    setLocationList(user!.locations);
    setShowEdit(true);
  };
  const handleShowModalDelete = (id: string) => {
    var user = users.find((x) => x.id === id);
    setActiveUser(user?.name + ' - Level' + user?.role_id);
    setUserToDelete(id);
    setShowDeleteUser(true);
  };

  const handleShowModalResetPassword = (id: string) => {
    var user = users.find((x) => x.id === id);
    setActiveUser(user?.name + ' - Level' + user?.role_id);
    setUserToReset(id);
    setShowResetPasswordUser(true);
  };
  const handleShowModalDeleteChecked = () => {
    setShowDeleteChecked(true);
  };
  const handleShowModalAddLocation = (id: string) => {
    var user = users.find((x) => x.id === id);
    setActiveUser(user?.name + ' - Level' + user?.role_id);
    var locationToAddList: userLocationList = [];
    var list = locationsOnUser;
    list.forEach((item) => {
      if (user!.locations.find((x) => x.id === item.id) === undefined) locationToAddList.push({ id: item.id, name: item.name });
    });
    setLocationList(locationToAddList);
    setUserToAddLocation(id);
    setShowAddLocation(true);
  };

  useEffect(() => {
    loadUsers();
  }, []);

  const loadUsers = async () => {
    try {
      let loadedUsers: userItemList = await getUsers();
      setUsers(loadedUsers);
      dispatch(
        setUserList({
          list: loadedUsers,
        })
      );
      const roleId = await getRoleId();
      setRoleId(roleId);
    } catch (e) {
      console.log(e);
    }
  };
  async function handleAddUser() {
    const email = emailInputValue.current!.value;
    const password = passwordInputValue.current!.value;
    const confirmPassword = confirmPasswordInputValue.current!.value;
    const level = levelSelection;
    const locations = createUserAddLocation;
    addUser(email, password, confirmPassword, level, locations)
      .then(async (response: userItemInList) => {
        window.location.reload();
      })
      .catch((e) => {
        console.log(e);
        if (e.response && e.response.data && e.response.data.error && e.response.data.error.message)
          toast.error(e.response.data.error.message);
        else
          toast.error(e.message as string);
        setHasError(true);
      })
      .finally(() => { });
  }
  async function handleDeleteUser(id: string) {
    var user_id = [];
    user_id.push(id);
    deleteUser(user_id)
      .then(async () => {
        var list = users;
        list = list.filter((userId) => userId.id !== id);
        setUsers([...list]);
        handleCloseModal();
      })
      .catch((e) => {
        setHasError(true);
      })
      .finally(() => { });
  }

  async function handleResetPasswordUser(id: string) {
    var user_id = [];
    user_id.push(id);
    resetPasswordUser(id)
      .then(async () => {
        handleCloseModal();
        toast.success('Password reseted');
      })
      .catch((e) => {
        setHasError(true);
      })
      .finally(() => { });
  }

  async function handleDeleteChecked(ids: string[]) {
    deleteUser(ids)
      .then(async () => {
        var list = users;
        ids.forEach((item) => {
          list = list.filter((userId) => userId.id !== item);
        });
        setUsers([...list]);
        handleCloseModal();
        setUsersToDelete([]);
      })
      .catch((e) => {
        setHasError(true);
      })
      .finally(() => { });
  }
  async function handleRemoveLocation(user_id: string, location_id: number) {
    deleteUserLocation(user_id, location_id)
      .then(async () => {
        window.location.reload();
      })
      .catch((e) => {
        setHasError(true);
        window.location.reload();
      })
      .finally(() => { });
  }
  function handleCreateUserAddLocation(location_id: number) {
    var list = createUserAddLocation;
    list.push(location_id);
    setCreateUserAddLocation([...list]);
  }
  function handleCreateUserRemoveLocation(location_id: number) {
    var list = createUserAddLocation;
    list = list.filter((x) => x !== location_id);
    setCreateUserAddLocation([...list]);
  }
  function handleFilterByRoleId() {
    var list = users;
    var sortedList = list.slice().sort((a, b) => b.role_id - a.role_id);
    setUsers([...sortedList]);
  }
  function handleFilterByLocationNr() {
    var list = users;
    var sortedList = list.slice().sort((a, b) => b.locations.length - a.locations.length);
    setUsers([...sortedList]);
  }
  async function handleAddLocationToUser(user_id: string, location_id: number) {
    addUserLocation(user_id, location_id)
      .then(async () => {
        window.location.reload();
      })
      .catch((e) => {
        setHasError(true);
      })
      .finally(() => { });
  }

  const handleRoleId = (user: userItemInList) => {
    setSelectedUser(user);
    setShowEditRole(true);
  };

  const handleRoleIdChange = (user: userItemInList) => {
    updateRoleId(user.role_id, user.id).then(() => {
      handleCloseModal();
      toast.success('Updated Role Id');
      loadUsers();
    });
  };

  const handleKeyPress = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      console.log('hi');
    }
  };

  useEffect(() => {
    console.log({ selectedUser });
  }, [selectedUser]);

  const handleChangeRoleId = (change: RoleChange) => {
    selectedUser &&
      setSelectedUser({
        ...selectedUser,
        role_id: selectedUser.role_id + change > 0 && selectedUser.role_id + change < 5 ? selectedUser.role_id + change : selectedUser.role_id,
      });
  };

  useEffect(() => {
    var list = locationsOnUserParam;
  
    setLocationsOnUser(
      list.filter((x) => {
        const searchValue = locationSearchValue.trim().toLowerCase();
  
        // Convert both the id and the searchValue to strings for comparison
        const idAsString = x.id.toString(); // Convert id to string
  
        return (
          idAsString.includes(searchValue) || // Check if search value matches the id
          x.name.toLowerCase().includes(searchValue) // Or check if it matches the name
        );
      })
    );
  }, [locationSearchValue]);

  return (
    <>
      <ToastContainer />
      {users.length !== 0 && users.find((x) => x.role_id >= 4) && (
        <div className={`card ${className}`}>
          <>
            <div className='card-header border-0 pt-5 d-flex flex-column flex-sm-row justify-content-between'>
              <h3 className='card-title align-items-start flex-column'>
                <span className='card-label fw-bold fs-3 mb-1'>Users</span>
                <span className='text-muted mt-1 fw-semibold fs-7'>{users.length} members</span>
              </h3>
              <div className='d-flex flex-row gap-3 mt-4 m-sm-auto me-sm-0'>
                <a
                  className={
                    usersToDelete.length != 0
                      ? 'text-danger p-3 rounded bg-light-danger cursor-pointer d-block'
                      : 'text-danger p-3 rounded bg-light-danger cursor-pointer d-none'
                  }
                  onClick={handleShowModalDeleteChecked}
                >
                  Delete selected
                </a>
                <a className='text-primary bg-light-primary p-3 rounded cursor-pointer' onClick={handleShowModal}>
                  New User
                </a>
              </div>
            </div>
            <div className='card-body py-md-3'>
              <div className='table-responsive'>
                <table
                  className={`table d-flex flex-column d-md-table table-row-gray-300 align-middle gs-0 gy-4 ${window.innerWidth > 768 ? 'table-row-dashed' : ''
                    }`}
                >
                  <thead>
                    <tr className='fw-bold text-muted d-none d-md-table-row'>
                      <th className='col-sm-1'>
                        <div className='form-check form-check-sm form-check-custom form-check-solid'>
                          {/* <input
                                            className='form-check-input'
                                            type='checkbox'
                                            value='1'
                                            data-kt-check='true'
                                            data-kt-check-target='.widget-9-check'
                                        /> */}
                        </div>
                      </th>
                      <th className='col-sm-3 cursor-pointer' onClick={handleFilterByRoleId}>
                        User
                      </th>
                      <th className='col-sm-2 cursor-pointer' onClick={handleFilterByLocationNr}>
                        Locations
                      </th>
                      <th className='col-sm-4'>Locations by ID</th>
                      <th className='text-end'></th>
                      <th className='col-sm-2 text-end'>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {users?.map((item) => {
                      return (
                        <tr className='d-flex flex-column d-md-table-row' key={item.id}>
                          <td>
                            <div className='form-check form-check-sm form-check-custom form-check-solid'>
                              <input
                                className='form-check-input widget-9-check'
                                type='checkbox'
                                value='1'
                                onChange={(event) => {
                                  handleCheckboxChange(event, item.id);
                                }}
                              />
                            </div>
                          </td>
                          <td>
                            <div className='d-flex align-items-center'>
                              {/* <div className='symbol symbol-45px me-5'>
                                        <i className='bi bi-person' />
                                    </div> */}
                              <div className='d-flex justify-content-start flex-column'>
                                <a href='#' className='text-dark fw-bold text-hover-primary fs-6'>
                                  {item.name}
                                </a>
                                {roleId && (
                                  <div
                                    className={`text-muted fw-semibold text-muted d-block fs-7 ${roleId > 4 ? 'cursor-pointer bg-secondary align-self-start px-1 rounded text-hover-primary' : ''
                                      }`}
                                    onClick={roleId > 4 ? () => handleRoleId(item) : () => { }}
                                  >
                                    Level {item.role_id}
                                  </div>
                                )}
                              </div>
                            </div>
                          </td>
                          <td>
                            <span className='text-muted fw-semibold text-muted d-block fs-7'>{item.locations.length} locations</span>
                          </td>
                          <td className='d-flex w-100 justify-content-start align-items-center flex-wrap gap-5 d-md-table-cell'>
                            {item.locations.map((location, index) => {
                              return (
                                <div key={location.id} className='d-flex justify-content-center d-md-inline-flex w-25 w-md-auto badge badge-light'>
                                  <span className=''>{location.id}</span>
                                  {item.locations.length != 1 && (
                                    <i className='bi bi-x cursor-pointer' onClick={() => handleShowModalDeleteLocation(item.id, location.id)} />
                                  )}
                                </div>
                              );
                            })}
                          </td>
                          <td className=''>
                            <a
                              className='btn btn-light-primary btn-sm me-1'
                              onClick={() => {
                                handleShowModalResetPassword(item.id);
                              }}
                            >
                              Reset password
                            </a>
                          </td>
                          <td>
                            <div className='d-flex justify-content-end gap-3 gap-md-0 flex-shrink-0'>
                              {locationsOnUserParam.length !== item.locations.length && (
                                <a
                                  onClick={() => {
                                    handleShowModalAddLocation(item.id);
                                  }}
                                  className='btn btn-icon btn-primary btn-sm me-1'
                                >
                                  <i className='bi bi-plus' />
                                </a>
                              )}
                              <a
                                onClick={() => {
                                  handleShowModalEdit(item.id);
                                }}
                                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                              >
                                <i className='bi bi-bar-chart' />
                              </a>
                              {/* <a
                                                    href='#'
                                                    className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                                                >
                                                    <i className='bi bi-bar-chart' />
                                                </a> */}
                              <a
                                onClick={() => {
                                  handleShowModalDelete(item.id);
                                }}
                                href='#'
                                className='btn btn-icon btn-danger  btn-sm'
                              >
                                <i className='bi bi-trash' />
                              </a>
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
            {/* MODALE */}
            {/* MODAL CREATE USER*/}
            <ReactBootstrap.Modal key={'add'} size='lg' show={show} onHide={handleCloseModal}>
              <ReactBootstrap.Modal.Header>
                <h2>Create user</h2>
                <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleCloseModal}>
                  <i className='bi bi-x' style={{ fontSize: '2rem' }} />
                </div>
              </ReactBootstrap.Modal.Header>
              <ReactBootstrap.Modal.Body className='d-flex justify-content-center pt-0'>
                <div className='d-flex flex-column'>
                  <form onKeyPress={handleKeyPress}>
                    <div className='fv-row my-6 d-flex flex-wrap'>
                      <label className='form-label fs-6 fw-bolder text-dark mb-2' htmlFor='email'>
                        Email
                      </label>
                      <input
                        className={'form-control bg-transparent' + (hasError ? ' border border-danger' : '')}
                        ref={emailInputValue}
                        type={'text'}
                        id='email'
                        placeholder='Email'
                      />
                    </div>
                    <div className='fv-row my-6 d-flex flex-wrap '>
                      <label className='form-label fw-bolder text-dark fs-6 mb-2' htmlFor='pass'>
                        Password
                      </label>
                      <input
                        className={'form-control bg-transparent' + (hasError ? ' border border-danger' : '')}
                        ref={passwordInputValue}
                        type={'password'}
                        id='pass'
                        placeholder='Password'
                      />
                    </div>
                    <div className='fv-row my-6 d-flex flex-wrap'>
                      <label className='form-label fs-6 fw-bolder text-dark mb-2' htmlFor='email'>
                        Confirm password
                      </label>
                      <input
                        className={'form-control bg-transparent' + (hasError ? ' border border-danger' : '')}
                        ref={confirmPasswordInputValue}
                        type={'password'}
                        id='confirm-pass'
                        placeholder='Confirm password'
                      />
                    </div>
                    <div className='text-gray-500 my-6 text-center fw-semibold fs-6'>
                      Password must contain at least one digit, one lower letter, one upper letter and one non alphanumeric character! &nbsp;
                    </div>
                    <div className='fv-row my-6 d-flex flex-wrap'>
                      <label className='form-label fs-6 fw-bolder text-dark mb-2' htmlFor='email'>
                        Level
                      </label>
                      {/* <input className={'form-control bg-transparent' + ((hasError) ? ' border border-danger' : '')} ref={levelInputValue} type={'text'} id='level' placeholder='Level' /> */}
                      <select
                        className='form-select fs-6 fw-bolder mb-2'
                        data-control='select2'
                        data-placeholder='Latest'
                        data-hide-search='true'
                        value={levelSelection}
                        onChange={(e) => {
                          setLevelSelection(parseInt(e.target.value));
                        }}
                      >
                        <option key={1} value={1} data-id={0}>
                          Level 1
                        </option>
                        <option key={2} value={2} data-id={0}>
                          Level 2
                        </option>
                        <option key={3} value={3} data-id={0}>
                          Level 3
                        </option>
                        <option key={4} value={4} data-id={0}>
                          Level 4
                        </option>
                        <option key={5} value={5} data-id={0}>
                          Level 5
                        </option>
                      </select>
                    </div>
                    <div className='fv-row my-6 d-flex flex-wrap'>
                      <label className='form-label fs-6 fw-bolder text-dark mb-2' htmlFor='locations'>
                        Locations
                      </label>
                      <input
                        className={'form-control bg-transparent' + (hasError ? ' border border-danger' : '')}
                        type={'text'}
                        placeholder='Search by name or ID'
                        value={locationSearchValue}
                        onChange={(e) => setLocationSearchValue(e.target.value)}
                      />
                      {/* <input className={'form-control bg-transparent' + ((hasError) ? ' border border-danger' : '')} ref={locationsInputValue} type={'text'} id='locations' placeholder='Locations' /> */}
                      <div className='mh-200px overflow-auto'>
                        {locationsOnUser.map((location) => {
                          return (
                            <div
                              key={location.id}
                              className={
                                'badge p-3 m-2 cursor-pointer ' +
                                (createUserAddLocation.find((x) => x === location.id) !== undefined ? ' badge-primary' : ' badge-light')
                              }
                              onClick={() =>
                                createUserAddLocation.find((x) => x === location.id) !== undefined
                                  ? handleCreateUserRemoveLocation(location.id)
                                  : handleCreateUserAddLocation(location.id)
                              }
                            >
                              <span className=''>{location.id}</span>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </form>
                </div>
              </ReactBootstrap.Modal.Body>
              <ReactBootstrap.Modal.Footer>
                <ReactBootstrap.Button variant='secondary' onClick={handleCloseModal}>
                  Close
                </ReactBootstrap.Button>
                <ReactBootstrap.Button variant='primary' onClick={handleAddUser}>
                  Create user
                </ReactBootstrap.Button>
              </ReactBootstrap.Modal.Footer>
            </ReactBootstrap.Modal>

            {/* MODAL VIEW USER*/}
            <ReactBootstrap.Modal key={'edit'} size='lg' show={showEdit} onHide={handleCloseModal}>
              <ReactBootstrap.Modal.Header>
                <h2>{activeUser}</h2>
                <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleCloseModal}>
                  <i className='bi bi-x' style={{ fontSize: '2rem' }} />
                </div>
              </ReactBootstrap.Modal.Header>
              <ReactBootstrap.Modal.Body className='d-flex justify-content-center pt-0 mh-500px'>
                <div className='table-responsive d-flex flex-fill'>
                  <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4 w-100'>
                    <thead>
                      <tr className='fw-bold text-muted'>
                        <th className='min-w-25'>Id</th>
                        <th className='min-w-50'>Address</th>
                        <th className='min-w-25 text-end'>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {locationList?.map((item) => {
                        return (
                          <tr key={item.id}>
                            <td>
                              <div className='d-flex align-items-center'>
                                {/* <div className='symbol symbol-45px me-5'>
                                    <i className='bi bi-person' />
                                </div> */}
                                <div className='d-flex justify-content-start flex-column'>
                                  <a href='#' className='text-dark fw-bold text-hover-primary fs-6'>
                                    {item.id}
                                  </a>
                                </div>
                              </div>
                            </td>
                            <td>
                              <span className='text-muted fw-semibold text-muted d-block fs-7'>{item.name}</span>
                            </td>
                            <td>
                              <div className='d-flex justify-content-end flex-shrink-0'>
                                <a
                                  className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                                  href={process.env.REACT_APP_HOMEPAGE + '/queue-management'}
                                  onClick={() => {
                                    dispatch(
                                      setValue({
                                        value: item.name,
                                        id: item.id.toString(),
                                      })
                                    );
                                  }}
                                >
                                  View
                                </a>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </ReactBootstrap.Modal.Body>
              <ReactBootstrap.Modal.Footer>
                <ReactBootstrap.Button variant='secondary' onClick={handleCloseModal}>
                  Close
                </ReactBootstrap.Button>
                {/* <ReactBootstrap.Button variant="primary">
                        View report
                    </ReactBootstrap.Button> */}
              </ReactBootstrap.Modal.Footer>
            </ReactBootstrap.Modal>

            {/* MODAL DELETE USER*/}
            <ReactBootstrap.Modal key={'delete'} size='lg' show={showDeleteUser} onHide={handleCloseModal}>
              <ReactBootstrap.Modal.Header>
                <h2>Delete user</h2>
                <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleCloseModal}>
                  <i className='bi bi-x' style={{ fontSize: '2rem' }} />
                </div>
              </ReactBootstrap.Modal.Header>
              <ReactBootstrap.Modal.Body className='mh-500px'>
                <div className=' '>
                  <span className='m-auto'>
                    {' '}
                    Are you sure you want to delete user <span className='fw-bold'>{activeUser}</span>
                  </span>
                </div>
              </ReactBootstrap.Modal.Body>
              <ReactBootstrap.Modal.Footer>
                <ReactBootstrap.Button variant='secondary' onClick={handleCloseModal}>
                  Close
                </ReactBootstrap.Button>
                <ReactBootstrap.Button variant='primary' onClick={() => handleDeleteUser(userToDelete)}>
                  Delete
                </ReactBootstrap.Button>
              </ReactBootstrap.Modal.Footer>
            </ReactBootstrap.Modal>

            {/* MODAL RESET PASSWORD USER*/}
            <ReactBootstrap.Modal key={'reset-password'} size='lg' show={showResetPasswordUser} onHide={handleCloseModal}>
              <ReactBootstrap.Modal.Header>
                <h2>Reset password</h2>
                <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleCloseModal}>
                  <i className='bi bi-x' style={{ fontSize: '2rem' }} />
                </div>
              </ReactBootstrap.Modal.Header>
              <ReactBootstrap.Modal.Body className='mh-500px'>
                <div className=' '>
                  <span className='m-auto'>
                    {' '}
                    Are you sure you want to reset password to default for user <span className='fw-bold'>{activeUser}</span>
                  </span>
                </div>
              </ReactBootstrap.Modal.Body>
              <ReactBootstrap.Modal.Footer>
                <ReactBootstrap.Button variant='secondary' onClick={handleCloseModal}>
                  Close
                </ReactBootstrap.Button>
                <ReactBootstrap.Button variant='primary' onClick={() => handleResetPasswordUser(userToReset)}>
                  Reset password
                </ReactBootstrap.Button>
              </ReactBootstrap.Modal.Footer>
            </ReactBootstrap.Modal>

            {/* MODAL DELETE USERS*/}
            <ReactBootstrap.Modal key={'delete-checked'} size='lg' show={showDeleteChecked} onHide={handleCloseModal}>
              <ReactBootstrap.Modal.Header>
                <h2>Delete user</h2>
                <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleCloseModal}>
                  <i className='bi bi-x' style={{ fontSize: '2rem' }} />
                </div>
              </ReactBootstrap.Modal.Header>
              <ReactBootstrap.Modal.Body className='mh-500px'>
                <div className=' mb-5'>
                  <h4 className='m-auto'> Are you sure you want to delete selected users?</h4>
                </div>
                <div>
                  {usersToDelete.map((item) => {
                    return <p key={item}>{users.find((x) => x.id === item)?.name}</p>;
                  })}
                </div>
              </ReactBootstrap.Modal.Body>
              <ReactBootstrap.Modal.Footer>
                <ReactBootstrap.Button variant='secondary' onClick={handleCloseModal}>
                  Close
                </ReactBootstrap.Button>
                <ReactBootstrap.Button variant='primary' onClick={() => handleDeleteChecked(usersToDelete)}>
                  Delete
                </ReactBootstrap.Button>
              </ReactBootstrap.Modal.Footer>
            </ReactBootstrap.Modal>

            {/* MODAL ADD LOCATION*/}
            <ReactBootstrap.Modal key={'add-location'} size='lg' show={showAddLocation} onHide={handleCloseModal}>
              <ReactBootstrap.Modal.Header>
                <h2>Add location to {activeUser}</h2>
                <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleCloseModal}>
                  <i className='bi bi-x' style={{ fontSize: '2rem' }} />
                </div>
              </ReactBootstrap.Modal.Header>
              <ReactBootstrap.Modal.Body className='d-flex justify-content-center pt-0 mh-500px'>
                <div className='table-responsive d-flex flex-fill'>
                  <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4 w-100'>
                    <thead>
                      <tr className='fw-bold text-muted'>
                        <th className='min-w-25'>Id</th>
                        <th className='min-w-50'>Address</th>
                        <th className='min-w-25 text-end'>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {locationList?.map((item) => {
                        return (
                          <tr key={item.id}>
                            <td>
                              <div className='d-flex align-items-center'>
                                {/* <div className='symbol symbol-45px me-5'>
                                    <i className='bi bi-person' />
                                </div> */}
                                <div className='d-flex justify-content-start flex-column'>
                                  <a href='#' className='text-dark fw-bold text-hover-primary fs-6'>
                                    {item.id}
                                  </a>
                                </div>
                              </div>
                            </td>
                            <td>
                              <span className='text-muted fw-semibold text-muted d-block fs-7'>{item.name}</span>
                            </td>
                            <td>
                              <div className='d-flex justify-content-end flex-shrink-0'>
                                <a
                                  className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                                  onClick={() => handleAddLocationToUser(userToAddLocation, item.id)}
                                >
                                  Add
                                </a>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </ReactBootstrap.Modal.Body>
              <ReactBootstrap.Modal.Footer>
                <ReactBootstrap.Button variant='secondary' onClick={handleCloseModal}>
                  Close
                </ReactBootstrap.Button>
              </ReactBootstrap.Modal.Footer>
            </ReactBootstrap.Modal>

            {/* MODAL DELETE LOCATION*/}
            <ReactBootstrap.Modal key={'remove-location'} size='lg' show={showRemoveLocation} onHide={handleCloseModal}>
              <ReactBootstrap.Modal.Header>
                <h2>Remove location</h2>
                <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleCloseModal}>
                  <i className='bi bi-x' style={{ fontSize: '2rem' }} />
                </div>
              </ReactBootstrap.Modal.Header>
              <ReactBootstrap.Modal.Body className='mh-500px'>
                <div className=' '>
                  <span className='m-auto'>
                    {' '}
                    Are you sure you want to remove location <span className='fw-bold'>{locationToRemove}</span> from user{' '}
                    <span className='fw-bold'>{activeUser}</span>
                  </span>
                </div>
              </ReactBootstrap.Modal.Body>
              <ReactBootstrap.Modal.Footer>
                <ReactBootstrap.Button variant='secondary' onClick={handleCloseModal}>
                  Close
                </ReactBootstrap.Button>
                <ReactBootstrap.Button variant='primary' onClick={() => handleRemoveLocation(userToRemoveLocation, locationToRemove)}>
                  Delete
                </ReactBootstrap.Button>
              </ReactBootstrap.Modal.Footer>
            </ReactBootstrap.Modal>

            {/* MODAL EDIT ROLE ID*/}
            {roleId && roleId > 4 && (
              <ReactBootstrap.Modal key={'role-id'} size='sm' show={showEditRole} onHide={handleCloseModal}>
                <ReactBootstrap.Modal.Header>
                  <h2>Change Role Id</h2>
                  <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleCloseModal}>
                    <i className='bi bi-x' style={{ fontSize: '2rem' }} />
                  </div>
                </ReactBootstrap.Modal.Header>
                <ReactBootstrap.Modal.Body className='mh-500px'>
                  <Form>
                    <Form.Group as={Row} className='mb-3 align-items-center' controlId='formRoleId'>
                      <Form.Label column sm='7'>
                        Role ID
                      </Form.Label>
                      <Col sm='3'>
                        <Form.Control
                          type='number'
                          placeholder='RoleId'
                          value={selectedUser?.role_id}
                          readOnly
                          onChange={(e) => {
                            console.log('e.currentTarget.value', e.currentTarget.value);
                            setSelectedUser((prev) => ({
                              ...prev!,
                              role_id: parseInt(e.currentTarget?.value, 10),
                            }));
                          }}
                        />
                      </Col>
                      <Col sm='2' className=''>
                        <ReactBootstrap.Button
                          className='py-0 pb-1 px-3 mb-1 bg-primary'
                          onClick={() => {
                            handleChangeRoleId(RoleChange.Add);
                          }}
                        >
                          <i className='bi bi-chevron-compact-up p-0 m-0' />
                        </ReactBootstrap.Button>
                        <ReactBootstrap.Button
                          className='py-0 pb-1 px-3 bg-primary'
                          onClick={() => {
                            handleChangeRoleId(RoleChange.Substract);
                          }}
                        >
                          <i className='bi bi-chevron-compact-down p-0 m-0' />
                        </ReactBootstrap.Button>
                      </Col>
                    </Form.Group>
                  </Form>
                </ReactBootstrap.Modal.Body>
                <ReactBootstrap.Modal.Footer>
                  <ReactBootstrap.Button variant='secondary' onClick={handleCloseModal}>
                    Close
                  </ReactBootstrap.Button>
                  <ReactBootstrap.Button variant='primary' onClick={() => selectedUser && handleRoleIdChange(selectedUser)}>
                    Submit
                  </ReactBootstrap.Button>
                </ReactBootstrap.Modal.Footer>
              </ReactBootstrap.Modal>
            )}
          </>
        </div>
      )}
    </>
  );
};

export { TablesWidget1 };

import { LeafMap } from './LeafMap';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { useEffect, useState } from 'react';
import useToolbarSetter from '../../hooks/Common/useToolbarSetter';

const MapLocationWrapper = () => {
  const { setToolbar } = useToolbarSetter();
  useEffect(() => {
    setToolbar('map', `Locations Map`);
  });
  const [markers, setMarkers] = useState<{ id: number; position: { lat: number; long: number }; name: string }[]>([]);
  const cachedLocationsList = useSelector((state: RootState) => state.cachedLocations);
  useEffect(() => {
    let forMarkers = new Array();
    cachedLocationsList.list.map((location) => {
      forMarkers.push({ id: location.id, position: { lat: location.lat, long: location.long }, name: `${location.id} - ${location.name}` });
    });
    setMarkers(forMarkers);
  }, [cachedLocationsList.list.length]);
  return (
    <div style={{ height: '93vh' }}>
      <LeafMap className={'h-100'} allowMultipleMarkers={true} allowDelete={true} markers={markers} allowAdd={false} draggableMarkers={false} />
    </div>
  );
};

export default MapLocationWrapper;

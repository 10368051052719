import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import bakeryShelfStatus from '../../../web_api/methods/bakeryDash/shelfStatus';
import { missingProductList, productEvolutionList, shelfStatusList, shelfStatusObj } from '../../../web_api/models';
import bakeryProductEvolution from '../../../web_api/methods/bakeryDash/productEvolution';
import { bakeryStatusColor, bakeryProductNameFormat, bakeryStatusToNumber } from '../../../web_api/methods/helpers';
import * as ReactBootstrap from 'react-bootstrap';
import { ChartsWidget17 } from '../../../_metronic/widgets/charts/ChartsWidget17';
import BakeryPDF from './BakeryPDF';
import bakeryMissingProduct from '../../../web_api/methods/bakeryDash/missingProduct';
import { MissingProducts } from './MissingProducts';
import LoadingPage from '../../../layout/LoadingPage';
import getAzureFile from '../../../web_api/methods/files/getAzureFile';
import useToolbarSetter from '../../../hooks/Common/useToolbarSetter';
import demoPicture from '../../../assets/images/default_bakery.jpg';
import securifaiLogo from '../../../assets/images/logo-small.png';
interface RouteParams {
  [key: string]: string | undefined;
}

const LocationInfoBakery: React.FC = () => {
  const { setToolbar } = useToolbarSetter();
  useEffect(() => {
    setToolbar('date-and-hour', `${activeLocation.value}`);
  });
  const params = useParams<RouteParams>();
  const locationId = params.locationId;
  const [shelfStatus, setShelfStatus] = useState<shelfStatusList>(new Array<shelfStatusObj>());
  const [shelfStatusPDF, setShelfStatusPDF] = useState<shelfStatusList[]>(new Array(new Array<shelfStatusObj>()));
  const activeLocation = useSelector((state: RootState) => state.activeLocation);
  const user_name = useSelector((state: RootState) => state.user.user_name);

  const toolbarHourFilter = useSelector((state: RootState) => state.toolbarHourFilter);
  const toolbarDateFilter = useSelector((state: RootState) => state.toolbarDateFilter);
  const tenantKey = useSelector((state: RootState) => state.user.tenant_key);
  const username = useSelector((state: RootState) => state.user.user_name);

  const [loading, setLoading] = useState(false);
  const [loadingMissingProducts, setLoadingMissingProducts] = useState(false);
  const [activeProduct, setActiveProduct] = useState<string>('');
  const [titleProduct, setTitleProduct] = useState<string>('');
  const [xAxis, setXAxis] = useState<string[]>([]);
  const [images, setImages] = useState<string[]>([]);
  const [headerEvolution, setHeaderEvolution] = useState<string[]>([]);
  const [missingProducts, setMissingProducts] = useState<missingProductList>([]);
  const [yAxis, setYAxis] = useState<number[]>([]);
  const [show, setShow] = useState(false);
  const [showExport, setShowExport] = useState(false);
  const [filename, setFilename] = useState('');
  const [url, setUrl] = useState('');
  const [hours, setHours] = useState<number[]>([]);
  const [activeHour, setActiveHour] = useState<number>(parseInt(toolbarHourFilter.hour));
  var [index, setIndex] = useState<number>(0);
  const [playerProgress, setPlayerProgress] = useState(0);
  const [timeoutIds, setTimeoutIds] = useState<NodeJS.Timeout>();
  const navigate = useNavigate();
  const handleCloseModal = () => {
    setShow(false);
  };

  const isDemoUser = () => username.includes('demo');

  const handleShowModal = () => {
    setFilename(shelfStatus.find((x) => x.hour === activeHour)?.filename ?? '');
    setShow(true);
  };
  const handleShowExportModal = () => {
    setShowExport(true);
  };
  const handleCloseExportModal = () => {
    setShowExport(false);
  };
  useEffect(() => {
    loadShelfStatus();
    loadMissingProducts();

    // console.log(hourList)
  }, [activeLocation.id, toolbarDateFilter.date, toolbarHourFilter.hour]);

  // useEffect(() => {
  //     // Function to be called every 15 seconds
  //     const myFunction = () => {
  //         // Code logic here
  //         // console.log('called ' + index)
  //         // setIndex(index+1)
  //         // setActiveHour(hours[index+1])
  //         // let i = index;
  //         // if (i < hours.length - 1) {
  //         //     setIndex((i) => {
  //         //         return i + 1
  //         //     });
  //         //     setActiveHour(hours[i + 1])
  //         // }
  //         // else {
  //         //     setIndex(0)
  //         //     setActiveHour(hours[0])
  //         // }
  //     };

  //     // Call the function immediately
  //     myFunction();

  //     // Set up the interval to call the function every 15 seconds
  //     const interval = setInterval(myFunction, 1000);

  //     // Clean up the interval on component unmount
  //     return () => {
  //         clearInterval(interval);
  //     };
  // }, []);

  useEffect(() => {
    if (activeProduct !== '') loadProductEvolution();
  }, [activeProduct, toolbarDateFilter.date, toolbarHourFilter.hour]);
  useEffect(() => {
    loadUrl();
  }, [filename]);
  const loadUrl = async () => {
    try {
      let getUrl: string = await getAzureFile(filename);
      setUrl(getUrl);
    } catch (e) {
      console.log(e);
    }
  };
  const loadShelfStatus = async () => {
    try {
      setLoading(true);
      let shelfStatus: shelfStatusList = await bakeryShelfStatus(
        parseInt(locationId!),
        new Date(toolbarDateFilter.date),
        parseInt(toolbarHourFilter.hour),
        parseInt(toolbarHourFilter.toHour)
      );
      // console.log(shelfStatus)
      setShelfStatus(shelfStatus);
      if (shelfStatus[0].filename !== undefined) setFilename(shelfStatus[0].filename);
      setLoading(false);
      const fromHour = parseInt(toolbarHourFilter.hour);
      const toHour = parseInt(toolbarHourFilter.toHour);
      const shelfStatusForPDF = new Array();
      const hourList = [];
      let currentHour = fromHour;
      while (currentHour <= toHour) {
        const oneShelfStatus = shelfStatus.filter((x) => x.hour === currentHour);
        shelfStatusForPDF.push(oneShelfStatus);
        hourList.push(currentHour);
        currentHour += 1;
      }
      setShelfStatusPDF(shelfStatusForPDF);
      setHours(hourList);
      setIndex(0);
      setActiveHour(hourList[0]);
    } catch (e) {
      console.log(e);
    }
  };
  const loadMissingProducts = async () => {
    try {
      setLoadingMissingProducts(false);
      let missingProducts: missingProductList = await bakeryMissingProduct(
        parseInt(locationId!),
        new Date(toolbarDateFilter.date),
        parseInt(toolbarHourFilter.hour),
        parseInt(toolbarHourFilter.toHour)
      );
      // console.log(shelfStatus)
      setMissingProducts(missingProducts);
      setLoadingMissingProducts(true);
    } catch (e) {
      console.log(e);
    }
  };

  const loadProductEvolution = async () => {
    try {
      let forX = Array();
      let forY = Array();
      let forHeaderEvolution = ['Product'];
      let date = new Date(toolbarDateFilter.date);
      let forImages = Array();
      let productEvolution: productEvolutionList = await bakeryProductEvolution(
        parseInt(locationId!),
        new Date(toolbarDateFilter.date),
        activeProduct
      );
      productEvolution.forEach(async (item) => {
        setTitleProduct(item.product);
        forHeaderEvolution.push(item.time);
        forX.push(item.time);
        forY.push(bakeryStatusToNumber(item.fill));
        forImages.push(await getAzureFile(item.filename));
      });

      setHeaderEvolution(forHeaderEvolution);
      setXAxis(forX);
      setYAxis(forY);
      setImages(forImages);
    } catch (e) {
      console.log(e);
    }
  };
  // Fetch data or perform other operations based on the locationId

  const handlePlayerClick = () => {
    const initialActiveHour = activeHour;

    const runPlayer = () => {
      const shelfStatusHours = shelfStatus.map((x) => x.hour);
      const uniqueHours = shelfStatusHours.filter((hour, index) => shelfStatusHours.indexOf(hour) === index);

      let currentIndex = uniqueHours.indexOf(initialActiveHour);

      if (currentIndex === -1) {
        currentIndex = uniqueHours.findIndex((hour) => hour > initialActiveHour);
        if (currentIndex === -1) {
          currentIndex = 0;
        }
      }

      let timeoutId;

      const setNextHour: () => NodeJS.Timeout | undefined = () => {
        if (currentIndex >= uniqueHours.length) {
          setActiveHour(parseInt(toolbarHourFilter.hour));
          setPlayerProgress(0);
          setTimeoutIds(undefined);
          return;
        }

        setActiveHour(uniqueHours[currentIndex]);

        currentIndex++;

        setPlayerProgress((currentIndex / uniqueHours.length) * 100);

        timeoutId = setTimeout(setNextHour, 1000);

        setTimeoutIds(timeoutId);
        return timeoutId;
      };
      timeoutId = setNextHour();
    };

    if (timeoutIds) {
      clearTimeout(timeoutIds);
      setTimeoutIds(undefined);
    } else {
      runPlayer();
    }
  };

  return (
    <>
      <div className='animated fadeInLeft position-absolute d-none d-xxl-block' style={{ top: '6%', left: '1.5%' }}>
        <i className='bi bi-shop-window bg-muted opacity-25' style={{ fontSize: '40.5rem' }}>
          <span
            className='bg-dark text-inverse-dark rounded-2 p-2 position-absolute fs-4x cursor-pointer'
            onClick={() => {
              navigate(-1);
            }}
            style={{ top: '6%', right: '20%' }}
          >
            <i className='bi bi-chevron-left fs-4x text-white' />
            {locationId}
          </span>
        </i>
        {/* Display the analytics data for the specific location */}
      </div>

      <div
        className='my-4 ms-3 h-25 me-auto bg-dark text-inverse-dark rounded-2 p-2 fs-2x cursor-pointer d-xxl-none opacity-25 animated fadeInLeft'
        onClick={() => {
          navigate(-1);
        }}
      >
        <i className='bi bi-chevron-left fs-2x text-white' />
        {locationId}
      </div>

      <div className='container'>
        <div className='d-flex flex-column flex-md-row animated zoomIn' style={{ marginTop: `${window.innerWidth > 1399 ? '6.25rem' : ''}` }}>
          <div className={`card transparent-to-white-gradient min-h-600px col-12`}>
            <div className='card-header ps-7 border-0 pt-5'>
              <h3 className='card-title align-items-start flex-column '>
                <span className='card-label fw-bold fs-3 mb-1'>Bakery shelf at {activeHour}:00</span>
              </h3>
              <span className='m-md-auto mb-4 w-100 w-md-auto'>Click on a product to see detailed data</span>
              <div className='m-md-auto me-md-2 ms-0'>
                <div
                  className='btn btn-sm btn-primary'
                  onClick={() => {
                    handleShowExportModal();
                  }}
                >
                  Generate report
                </div>
              </div>
              <span className='btn btn-sm btn-primary m-auto mx-0' onClick={handleShowModal}>
                See image
              </span>
            </div>
            <div className='card-body py-3'>
              <div className='tab-content'>
                {/* begin::Tap pane */}
                <div className='tab-pane fade show active' id='kt_table_widget_5_tab_1'>
                  {/* begin::Table container */}
                  {loading ? (
                    <LoadingPage />
                  ) : (
                    <div className='row'>
                      {shelfStatus
                        .filter((x) => x.hour === activeHour)
                        .map((item, index) => {
                          return (
                            <div
                              className='d-flex col-2 my-3 h-100px cursor-pointer px-1 px-xl-2 px-xxl-1'
                              key={`${index}-${locationId}`}
                              onClick={() => setActiveProduct(item.product)}
                            >
                              <div
                                id={'product-' + `${index}-${locationId}`}
                                className={`d-flex fw-bold text-center text-inverse-primary fs-10 fs-md-9 rounded bg-${bakeryStatusColor(
                                  item.fill
                                )} h-100 w-100`}
                              >
                                {/* {bakeryProductId(item.product)} */}
                                <span className='mx-md-auto mt-4 mt-md-6 px-1 text-break'>
                                  <p>{item.fill}</p>
                                  <p> {bakeryProductNameFormat(item.product)}</p>
                                </span>
                              </div>
                              {/* <ReactTooltip
                                                        className={`z-index-1 bg-${bakeryStatusColor(item.fill)}`}
                                                        anchorId={'product-' + `${index}-${locationId}`}
                                                        place="bottom"
                                                        content={bakeryProductNameFormat(item.product)}
                                                    /> */}
                            </div>
                          );
                        })}
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className='d-flex align-items-center px-5 px-md-9 flex-column py-0 w-100 card-footer'>
              {shelfStatus.length !== 0 && hours.length > 1 && (
                <>
                  <div className='d-flex flex-row w-100 mb-3'>
                    <div
                      className='btn m-md-auto btn-active-light'
                      onClick={() => {
                        if (index > 0) {
                          setIndex((index) => {
                            return index - 1;
                          });
                          setActiveHour(hours[index - 1]);
                        }
                      }}
                    >
                      <p className='text-dark m-md-auto'>Prev</p>
                      {/* <i className="bi bi-arrow-left text-white" /> */}
                    </div>
                    <p className='text-dark opacity-75 fw-semibold fs-6 m-auto col-sm-4 text-center'>{activeHour}:00</p>
                    <div
                      className='btn m-md-auto btn-active-light'
                      onClick={() => {
                        if (
                          index < hours.length - 1 &&
                          shelfStatus.filter((x) => x.hour === activeHour + 1).length !== 0 &&
                          shelfStatus.filter((x) => x.hour === activeHour + 1)[0].filename !==
                            shelfStatus.filter((x) => x.hour === activeHour)[0].filename
                        ) {
                          setIndex((index) => {
                            return index + 1;
                          });
                          setActiveHour(hours[index + 1]);
                        }
                      }}
                    >
                      <p className='text-dark m-md-auto'>Next</p>
                      {/* <i className="bi bi-arrow-right text-white" /> */}
                    </div>
                  </div>
                  <div className='py-md-6 pb-6 d-flex align-items-center animated w-100 px-3'>
                    <i
                      className={`${timeoutIds ? 'bi bi-pause-fill' : 'bi bi-play-fill'} cursor-pointer mb-1`}
                      onClick={handlePlayerClick}
                      style={{ fontSize: '3.5rem' }}
                    ></i>
                    <div
                      className='progress ml-3 flex-grow-1'
                      role='progressbar'
                      aria-label='Animated striped example'
                      aria-valuenow={playerProgress}
                      aria-valuemin={0}
                      aria-valuemax={100}
                    >
                      <div
                        className='progress-bar progress-bar-striped progress-bar-animated bg-primary'
                        style={{ width: `${playerProgress}%` }}
                      ></div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>

        <div className='d-flex flex-column flex-xxl-row'>
          <div className='d-flex flex-column col-xxl-6 p-0'>
            <div className='p-5 px-0 flex-fill'>
              <MissingProducts
                className={'h-475px'}
                title={'Products missing the most'}
                loading={loadingMissingProducts}
                missingProducts={missingProducts}
              />
            </div>
          </div>
          <div className='d-flex flex-row flex-xxl-column col-xxl-6 p-0'>
            <div className='p-xxl-5 pe-xxl-0 flex-fill'>
              {titleProduct !== '' && (
                <ChartsWidget17
                  type='bar'
                  className='w-sm-100'
                  chartColor='success'
                  chartHeight='150px'
                  xAxis={xAxis}
                  images={images}
                  yAxis={yAxis}
                  product={titleProduct}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <ReactBootstrap.Modal size='xl' show={show} onHide={handleCloseModal}>
        <ReactBootstrap.Modal.Header>
          <h2>
            {activeLocation.value} - {activeHour}:00
          </h2>
          <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleCloseModal}>
            <i className='bi bi-x' style={{ fontSize: '2rem' }} />
          </div>
        </ReactBootstrap.Modal.Header>
        <ReactBootstrap.Modal.Body className='d-flex justify-content-center'>
          <div>
            <img className='w-100' src={isDemoUser() ? demoPicture : url} alt={activeLocation.value} />
          </div>
        </ReactBootstrap.Modal.Body>
        <ReactBootstrap.Modal.Footer>
          <ReactBootstrap.Button variant='secondary' onClick={handleCloseModal}>
            Close
          </ReactBootstrap.Button>
        </ReactBootstrap.Modal.Footer>
      </ReactBootstrap.Modal>
      <ReactBootstrap.Modal size='lg' show={showExport} onHide={handleCloseExportModal}>
        <ReactBootstrap.Modal.Header>
          <h2>{activeLocation.value}</h2>
          <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleCloseExportModal}>
            <i className='bi bi-x' style={{ fontSize: '2rem' }} />
          </div>
        </ReactBootstrap.Modal.Header>
        <ReactBootstrap.Modal.Body className='d-flex justify-content-center'>
          <BakeryPDF
            headers={['Id', 'Name', 'Fill', 'Availability']}
            // headers={['Position', 'Id', 'Name', 'Fill', 'Availability']}
            data={shelfStatusPDF}
            location={{
              id: parseInt(activeLocation.id),
              name: activeLocation.value,
            }}
            user_name={user_name}
            date={new Date(toolbarDateFilter.date).toLocaleDateString('en-US', { weekday: 'short', month: 'short', day: '2-digit', year: 'numeric' })}
            fileName={`${activeLocation.id} - Bakery - ${activeLocation.value} - ${new Date(toolbarDateFilter.date).toLocaleDateString('en-US', {
              weekday: 'short',
              month: 'short',
              day: '2-digit',
              year: 'numeric',
            })}`}
            missingProducts={missingProducts}
            altLogo={isDemoUser()}
          />
        </ReactBootstrap.Modal.Body>
        <ReactBootstrap.Modal.Footer>
          <ReactBootstrap.Button variant='secondary' onClick={handleCloseExportModal}>
            Close
          </ReactBootstrap.Button>
        </ReactBootstrap.Modal.Footer>
      </ReactBootstrap.Modal>
    </>
  );
};

export default LocationInfoBakery;

import React, { useEffect, useState } from 'react';
import { shelfStatusList } from '../../../web_api/models';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import { bakeryProductNameFormat, bakeryStatusColor } from '../../../web_api/methods/helpers';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import * as ReactBootstrap from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setValue } from '../../../redux/activeLocationReducer';
import { setValue as setBakeryHourFilter } from '../../../redux/toolbarHourFilterReducer';
import bakeryShelfCurrentStatus from '../../../web_api/methods/bakeryDash/shelfCurrentStatus';
import LoadingPage from '../../../layout/LoadingPage';
import getAzureFile from '../../../web_api/methods/files/getAzureFile';
import demoPicture from '../../../assets/images/default_bakery.jpg';

type Props = {
  locationId: number;
  locationName: string;
  className: string;
  delay: string;
};

const LocationStatus: React.FC<Props> = ({ locationId, locationName, className, delay }) => {
  const [shelfStatus, setShelfStatus] = useState<shelfStatusList>();
  const tenantKey = useSelector((state: RootState) => state.user.tenant_key);

  const toolbarDateFilter = useSelector((state: RootState) => state.toolbarDateFilter);
  const username = useSelector((state: RootState) => state.user.user_name);
  const [loading, setLoading] = useState(false);
  const [loadingImage, setLoadingImage] = useState(false);
  const [show, setShow] = useState(false);
  const [filename, setFilename] = useState('');
  const [url, setUrl] = useState('');
  const dispatch = useDispatch();
  const handleCloseModal = () => {
    setShow(false);
  };

  const isDemoUser = () => username.includes('demo');

  const handleShowModal = () => {
    setShow(true);
    loadUrl();
  };
  const handleViewLocation = () => {
    dispatch(
      setValue({
        value: locationName,
        id: locationId.toString(),
      })
    );
    // dispatch(setToolbar(
    //     {
    //         toolbar: 'date-and-hour',
    //         title: locationName
    //     }))
    dispatch(
      setBakeryHourFilter({
        hour: '9',
        toHour: '21',
      })
    );
  };
  const loadUrl = async () => {
    try {
      if (!filename) return;
      setLoadingImage(true);
      let getUrl: string = await getAzureFile(filename);
      setUrl(getUrl);
      setLoadingImage(false);
    } catch (e) {
      console.log(e);
    }
  };
  useEffect(() => {
    loadShelfStatus();
  }, [locationId, toolbarDateFilter]);
  const loadShelfStatus = async () => {
    try {
      setLoading(true);
      console.log({ locationId });
      let shelfStatus: shelfStatusList = await bakeryShelfCurrentStatus(locationId, new Date(toolbarDateFilter.date));
      setShelfStatus(shelfStatus);
      if (shelfStatus[0].filename !== undefined) setFilename(shelfStatus[0].filename);
      setLoading(false);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <>
      {shelfStatus?.length !== 0 && (
        <div className={`d-flex flex-column col-12 col-md-6 col-xxl-4 my-3 h-min-300px ${className}`} style={{ animationDelay: `${delay}` }}>
          <div className={`card min-h-300px ${window.innerWidth > 576 ? 'animated hover-grow' : ''}`}>
            <div className='card-header border-0 pt-5'>
              <div className='d-flex gap-5 cursor-pointer'>
                <div className='d-flex'>
                  <i className='bi bi-shop-window bg-muted text-hover-primary flex-center d-flex mb-2 opacity-25' style={{ fontSize: '4.5rem' }}>
                    <span className='bg-dark text-inverse-dark rounded-2 p-2 position-absolute fs-7' style={{ top: '3%' }}>
                      {locationId}
                    </span>
                  </i>
                </div>
                <div className='d-flex'>
                  <p className='m-auto'>
                    <Link to={`/bakery/analytics/${locationId}`} className='fs-6 fw-bold text-dark text-hover-primary' onClick={handleViewLocation}>
                      {' '}
                      {locationName}
                    </Link>
                  </p>
                </div>
              </div>
            </div>
            <hr className='border border-secondary border-3 opacity-50 mx-6 my-0' />
            <div className='card-body py-3 overflow-auto'>
              <div className='tab-content'>
                {/* begin::Tap pane */}
                <div className='tab-pane fade show active' id='kt_table_widget_5_tab_1'>
                  {/* begin::Table container */}
                  {loading ? (
                    <LoadingPage />
                  ) : (
                    <div className='row'>
                      {shelfStatus?.map((item, index) => {
                        return (
                          <div className='d-flex col-2 my-3 h-30px px-1 px-xl-2 px-xxl-1' key={`${item.product}-${locationId}`}>
                            <div
                              id={'product-' + `${index}-${locationId}`}
                              className={`d-flex fw-bold text-center text-inverse-primary fs-10 fs-sm-9 rounded bg-${bakeryStatusColor(
                                item.fill
                              )} h-100 w-100`}
                            >
                              {/* {bakeryProductId(item.product)} */}
                              <span className='m-auto'>{item.fill}</span>
                            </div>
                            <ReactTooltip
                              className={`z-index-1 bg-${bakeryStatusColor(item.fill)}`}
                              anchorId={'product-' + `${index}-${locationId}`}
                              place='bottom'
                              content={bakeryProductNameFormat(item.product)}
                            />
                          </div>
                        );
                      })}
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className='card-footer p-5 d-flex flex-row justify-content-between'>
              <span className='btn btn-sm btn-primary ' onClick={handleShowModal}>
                See image
              </span>
              <Link
                to={`${process.env.REACT_APP_HOMEPAGE}/bakery/analytics/${locationId}`}
                className='btn btn-sm btn-primary'
                onClick={handleViewLocation}
              >
                View more
              </Link>
            </div>
          </div>
        </div>
      )}
      <ReactBootstrap.Modal size='xl' show={show} onHide={handleCloseModal}>
        <ReactBootstrap.Modal.Header>
          <h2>{locationName} - last status</h2>
          <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleCloseModal}>
            <i className='bi bi-x' style={{ fontSize: '2rem' }} />
          </div>
        </ReactBootstrap.Modal.Header>
        <ReactBootstrap.Modal.Body className='d-flex justify-content-center'>
          {loadingImage ? (
            <LoadingPage />
          ) : (
            <div>
              <img className='w-100' src={isDemoUser() ? demoPicture : url} alt={locationName} />
            </div>
          )}
        </ReactBootstrap.Modal.Body>
        <ReactBootstrap.Modal.Footer>
          <ReactBootstrap.Button variant='secondary' onClick={handleCloseModal}>
            Close
          </ReactBootstrap.Button>
        </ReactBootstrap.Modal.Footer>
      </ReactBootstrap.Modal>
    </>
  );
};
export { LocationStatus };

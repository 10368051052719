import { ChangeEvent, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { RootState } from '../../redux/store';
import { setCachedLocationList } from '../../redux/cachedLocationsReducer';
import { CommonModal } from '../Common/CommonModal';
import addLocation from '../../web_api/methods/settings/locations/addLocation';
import updateLocation from '../../web_api/methods/settings/locations/updateLocation';
import FormField from '../Common/CommonFormField';
import { toast } from 'react-toastify';
import usePut from '../../hooks/Common/usePut';
import { LeafMap } from '../Map/LeafMap';
import { Dropdown, Form } from 'react-bootstrap';
import addPlanPicture from '../../web_api/methods/settings/locations/addPlanPicture';
import CommonFormDropdownField from '../Common/CommonFormDropdownField';
import useGet from '../../hooks/Common/useGet';
import getCounties from '../../web_api/methods/settings/locations/getCounties';
import getLocations from '../../web_api/methods/settings/locations/getLocations';
import { locationSchedule } from '../../web_api/models';
import getLocationSchedule from '../../web_api/methods/settings/locations/getLocationSchedule';
import addLocationSchedule from '../../web_api/methods/settings/locations/addLocationSchedule';

type LocationsFormModalProps = {
  onModalToggle: () => void;
  show: boolean;
  currentLocation: {
    id: number;
    name: string;
    lat: number;
    long: number;
    county_id: number | null;
  };
};

const LocationsFormModal: React.FC<LocationsFormModalProps> = ({ onModalToggle, show, currentLocation }) => {
  const cachedLocationsList = useSelector((state: RootState) => state.cachedLocations);
  const [isAdding, setIsAdding] = useState<boolean>(false);
  const dispatch = useDispatch();

  const { loading, data: counties, loadItems } = useGet([], getCounties);

  const {
    loading: loadingSchedule,
    data: schedule,
    loadItems: loadSchedule,
    setData: setSchedule,
  } = useGet([currentLocation.id], () => getLocationSchedule(currentLocation.id));

  const { isFormValid, setIsFormValid, data, setData, handleUpdateData, errors, isUpdating } = usePut(
    {
      name: currentLocation.name,
      lat: currentLocation.lat,
      long: currentLocation.long,
      county_id: currentLocation.county_id,
    },
    () =>
      updateLocation({
        id: currentLocation.id ? currentLocation.id : 0,
        name: data.name,
        lat: data.lat,
        lng: data.long,
        county_id: data.county_id,
      }),
    setCachedLocationList,
    cachedLocationsList.list,
    'id',
    currentLocation.id,
    () => {
      onModalToggle();
      toast.success(`Location updated successfully!`, {
        position: 'top-right',
        theme: 'colored',
        autoClose: 2000,
      });
    }
  );

  useEffect(() => {
    if (errors) {
      toast.error(`${errors}`, {
        position: 'top-right',
        theme: 'colored',
      });
    }
  }, [errors?.length]);

  const resetFields = () => {
    setData({
      name: '',
      lat: 0,
      long: 0,
      county_id: null,
    });
    setIsFormValid({
      name: '',
      lat: '',
      long: '',
      county_id: '',
    });
  };

  const handleAddLocation = async () => {
    if (!data.name || !data.lat || !data.long) {
      setIsFormValid({
        name: !data.name ? 'is-invalid' : 'is-valid',
        lat: !data.lat ? 'is-invalid' : 'is-valid',
        long: !data.long ? 'is-invalid' : 'is-valid',
        county_id: !data.county_id ? 'is-invalid' : 'is-valid',
      });
      return;
    }
    try {
      setIsFormValid({
        name: '',
        lat: '',
        long: '',
        county_id: '',
      });
      setIsAdding(true);
      let locationToAdd = await addLocation({
        name: data.name,
        lat: data.lat,
        long: data.long,
        county_id: data.county_id,
      });

      const updatedList = [...cachedLocationsList.list];

      updatedList.unshift({
        name: data.name,
        lat: data.lat,
        long: data.long,
        id: locationToAdd.id,
        location_type: null,
        city_id: 0,
        type: '',
        tenant_id: 26,
        county_id: data.county_id,
      });
      dispatch(setCachedLocationList({ list: updatedList }));
      onModalToggle();
      toast.success(`Location added successfully!`, {
        position: 'top-right',
        theme: 'colored',
        autoClose: 2000,
      });
    } catch (e) {
      toast.error(`${e}`, {
        position: 'top-right',
        theme: 'colored',
      });
    } finally {
      setIsAdding(false);
      setTimeout(() => resetFields(), 500);
    }
  };

  const handleUpdateDataFromMarker = (name: string, lat: number, long: number) => {
    setData((prev: any) => ({
      name: name,
      lat: lat,
      long: long,
      county_id: prev.county_id,
    }));
  };

  useEffect(() => {
    if (currentLocation.id) {
      setData({
        name: currentLocation.name,
        lat: currentLocation.lat,
        long: currentLocation.long,
        county_id: currentLocation.county_id,
      });
    } else {
      setData({
        name: '',
        lat: 0,
        long: 0,
        county_id: null,
      });
    }
  }, [currentLocation.id]);

  const testScheduleTimes = () => {
    if (!schedule) return false;
    const timeRegex = /^(?:[0-9]|[01]\d|2[0-3]):[0-5]\d$/;

    if (!timeRegex.test(schedule.start_time_ls)) return false;
    if (!timeRegex.test(schedule.end_time_ls)) return false;
    if (!timeRegex.test(schedule.start_time_d)) return false;
    if (!timeRegex.test(schedule.end_time_d)) return false;
    return true;
  };

  return (
    <div>
      {!loading && counties && counties.length > 0 && (
        <CommonModal
          title={currentLocation.id ? 'Edit the location' : 'Add a location'}
          size='lg'
          show={show}
          showModalCallback={onModalToggle}
          closeButton={true}
          confirmButton={true}
          disabled={currentLocation.id ? isUpdating : isAdding}
          onExited={currentLocation.id ? undefined : resetFields}
          bodyClass='flex-column w-100 ps-15 pe-15 pb-10'
          closeButtonOptions={{
            icon: 'bi bi-x',
            variant: 'secondary',
            class: '',
          }}
          confirmButtonOptions={{
            icon: 'bi bi-check-lg text-white',
            variant: 'custom',
            class: `bg-primary text-white ${data.name.length > 0 ? '' : 'cursor-not-allowed'}`,
            onClick:
              currentLocation.id !== 0
                ? async () => {
                    await handleUpdateData().then(async () => {
                      dispatch(setCachedLocationList({ list: await getLocations() }));
                    });
                    if (testScheduleTimes()) {
                      schedule &&
                        (await addLocationSchedule({
                          location_id: schedule.location_id,
                          start_time_ls: schedule.start_time_ls,
                          end_time_ls: schedule.end_time_ls,
                          start_time_d: schedule.start_time_d,
                          end_time_d: schedule.end_time_d,
                        }).then((res) => {
                          toast.success('Schedule updates successfully');
                        }));
                    } else {
                      toast.error('Schedule times provided are wrong');
                    }
                  }
                : handleAddLocation,
            disabledBtn: !(data.name.length > 0) || !(data.lat > 0) || !(data.long > 0),
          }}
        >
          <FormField
            inputClass={isFormValid.name}
            id='name'
            label='Name'
            placeholder='Location name'
            type='text'
            value={data.name}
            onChange={(e) => setData({ ...data, name: e.target.value })}
            invalidFeedback=''
            feedbackClass='d-none'
          />
          <i className='text-muted'>Please fill the Name field before placing the marker on the map. You can move it afterwards</i>
          {data.name.length > 0 && !loading && (
            <div>
              <div className='d-flex flex-row mt-2 align-items-end justify-content-between'>
                <FormField
                  inputClass={isFormValid.lat}
                  id='lat'
                  label='Latitude'
                  placeholder='Latitude'
                  type='number'
                  value={data.lat}
                  onChange={(e) => setData({ ...data, lat: parseFloat(e.target.value) })}
                  invalidFeedback=''
                  feedbackClass='d-none'
                />
                <FormField
                  inputClass={isFormValid.long}
                  id='long'
                  label='Longitude'
                  placeholder='Longitude'
                  type='number'
                  value={data.long}
                  onChange={(e) => setData({ ...data, long: parseFloat(e.target.value) })}
                  invalidFeedback=''
                  feedbackClass='d-none'
                />
                <Dropdown className=''>
                  <Dropdown.Toggle variant='outline-light btn' id='dropdown-basic'>
                    {data.county_id === null ? `Select County` : counties.find((x) => x.id === data.county_id)?.county}
                  </Dropdown.Toggle>

                  <Dropdown.Menu style={{ height: '200px', overflowY: 'auto' }}>
                    <Dropdown.Item
                      key={-1}
                      onClick={() =>
                        setData((data: any) => {
                          return { ...data, county_id: 0 };
                        })
                      }
                    >
                      Select County
                    </Dropdown.Item>
                    {counties.map((x) => {
                      return (
                        <Dropdown.Item
                          key={x.id}
                          onClick={() =>
                            setData((data: any) => {
                              return { ...data, county_id: x.id };
                            })
                          }
                        >
                          {x.county}
                        </Dropdown.Item>
                      );
                    })}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
              <LeafMap
                className={`h-300px bg-secondary mt-5`}
                data={data}
                currentMarker={
                  currentLocation.id !== 0
                    ? [
                        {
                          id: currentLocation.id,
                          position: {
                            lat: currentLocation.lat,
                            long: currentLocation.long,
                          },
                          name: currentLocation.name,
                        },
                      ]
                    : undefined
                }
                markers={[]}
                allowAdd={true}
                onMarkerAddedOrChanged={handleUpdateDataFromMarker}
                zoom={6}
              />
              <Form.Group controlId='formFile' className={`border-top border-black border-2 pt-2`}>
                <Form.Label>Add Location Plan</Form.Label>
                <Form.Control
                  type='file'
                  multiple
                  onChange={async (event: ChangeEvent<HTMLInputElement>) => {
                    if (event.currentTarget.files) {
                      for (let i = 0; i < event.currentTarget.files.length; i++) {
                        const formData = new FormData();
                        const file = event.currentTarget.files[i];
                        formData.append(file.name, file);

                        // Convert the file to a blob
                        const blob = new Blob([file], { type: file.type });

                        // Create an image element to read the dimensions
                        const img = new Image();
                        const reader = new FileReader();

                        reader.onload = function (e) {
                          const result = e.target?.result;
                          if (typeof result === 'string') {
                            img.src = result; // Assign only if result is a string
                          } else {
                            console.error('FileReader result is not a string');
                          }
                        };

                        img.onload = async function () {
                          const width = img.width;
                          const height = img.height;

                          // Assuming addPlanPicture function accepts a blob, width, and height
                          await addPlanPicture(blob, currentLocation.id, width, height).then(() => {
                            toast.success(`Location plan added successfully!`, {
                              position: 'top-right',
                              theme: 'colored',
                              autoClose: 2000,
                            });
                          });
                        };

                        reader.readAsDataURL(file);
                      }
                    }
                  }}
                />
              </Form.Group>
              {!loadingSchedule && (
                <>
                  <div className='d-flex flex-row mt-2 align-items-end justify-content-between'>
                    <FormField
                      id='startTimeLS'
                      label='Start time L-S (hh:mm)'
                      placeholder='Start time L-S'
                      type='text'
                      value={schedule?.start_time_ls || ''}
                      onChange={(e) => setSchedule((prev) => ({ ...prev!, start_time_ls: e.target.value, location_id: currentLocation.id }))}
                      invalidFeedback=''
                      feedbackClass='d-none'
                      containerClass='w-50 pe-2'
                    />
                    <FormField
                      id='endTimeLS'
                      label='End time L-S (hh:mm)'
                      placeholder='End time L-S'
                      type='text'
                      value={schedule?.end_time_ls || ''}
                      onChange={(e) => setSchedule((prev) => ({ ...prev!, end_time_ls: e.target.value, location_id: currentLocation.id }))}
                      invalidFeedback=''
                      feedbackClass='d-none'
                      containerClass='w-50 ps-2'
                    />
                  </div>
                  <div className='d-flex flex-row mt-2 align-items-end justify-content-between'>
                    <FormField
                      id='startTimeD'
                      label='Start time D (hh:mm)'
                      placeholder='Start time D'
                      type='text'
                      value={schedule?.start_time_d || ''}
                      onChange={(e) => setSchedule((prev) => ({ ...prev!, start_time_d: e.target.value, location_id: currentLocation.id }))}
                      invalidFeedback=''
                      feedbackClass='d-none'
                      containerClass='w-50 pe-2'
                    />
                    <FormField
                      id='endTimeD'
                      label='End time D (hh:mm)'
                      placeholder='End time D'
                      type='text'
                      value={schedule?.end_time_d || ''}
                      onChange={(e) => setSchedule((prev) => ({ ...prev!, end_time_d: e.target.value, location_id: currentLocation.id }))}
                      invalidFeedback=''
                      feedbackClass='d-none'
                      containerClass='w-50 ps-2'
                    />
                  </div>
                </>
              )}
            </div>
          )}
        </CommonModal>
      )}
    </div>
  );
};

export default LocationsFormModal;

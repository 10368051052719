import './App.css';
import Login from './pages/Login';
import Register from './pages/Account/Register/Register';
import PrivateRoutes from './routing/PrivateRoutes';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from './redux/store';
import { ToastContainer, toast } from 'react-toastify';
import { useEffect } from 'react';
import SignalRService from './web_api/hubs/SignalRService';
import { alertNotification, detectionItemInList } from './web_api/models';
import getAzureFile from './web_api/methods/files/getAzureFile';
import getDetections from './web_api/methods/alerts/getDetections';
import { useDispatch } from 'react-redux';
import { setAlerts } from './redux/alertsReducer';

export const NotificationToast = ({ alert, imgUrl }: { alert: alertNotification; imgUrl: string }) => {
  return (
    <div className='d-flex flex-row align-items-center justify-content-between gap-4'>
      {imgUrl !== '' && (
        <div>
          <img src={imgUrl} alt='notification' width={'150px'} height={'auto'} id={imgUrl} />
        </div>
      )}
      <div>
        New <strong>{alert.type}</strong> alert received!
      </div>
    </div>
  );
};

function App() {
  const token = useSelector((state: RootState) => state.user.access_token);
  const tenantKey = useSelector((state: RootState) => state.user.tenant_key);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const computeUrlNotif = async (filename: string, date: string) => {
    const res = await getAzureFile(filename);

    return res;
  };

  const computeUrlAlert = async (alert: detectionItemInList) => {
    const res = await getAzureFile(alert.filename);
    return res;
  };

  // Subscribe to receive alerts
  useEffect(() => {
    SignalRService.receiveAlert(async (receivedAlert: alertNotification) => {
      const url = await computeUrlNotif(receivedAlert.image, receivedAlert.date);

      let res = await getDetections(undefined, undefined, 1, 5, new Date(Date.now()), new Date(Date.now()));
      // set each alert filename to computeUrlAlert(filename)

      res = await Promise.all(
        res.map(async (alert) => {
          alert.filename = await computeUrlAlert(alert);
          return alert;
        })
      );

      dispatch(setAlerts(res));

      toast.warning(<NotificationToast imgUrl={url} alert={receivedAlert} />, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        icon: false,
        toastId: receivedAlert.image,
        onClick: () => {
          navigate('/alerts/last-alerts');
        },
      });
    });
  }, []);

  return (
    <>
      <ToastContainer stacked />
      <Routes>
        {token !== null ? (
          <>
            <Route path='/*' element={<PrivateRoutes />} />
            <Route index element={<Navigate to={process.env.REACT_APP_HOMEPAGE + '/home'} />} />
          </>
        ) : (
          <>
            <Route path='/*' index element={<Login />}></Route>
            <Route path={process.env.REACT_APP_HOMEPAGE + '/account/register'} element={<Register />} />
          </>
        )}
      </Routes>
    </>
  );
}

export default App;

import { avgTimes, locationsList } from '../../web_api/models';

export const defaultAvgCashRegisterOpen: avgTimes[] = [
  {
    location_id: 1212,
    avg_time: '3m25s',
    queue_duration: '5m10s',
  },
  {
    location_id: 2323,
    avg_time: '4m13s',
    queue_duration: '3m55s',
  },
];

export const demoLocationsList: locationsList = [
  {
    city_id: 33,
    county_id: 1,
    id: 1212,
    lat: 22,
    location_type: 3,
    long: 33,
    name: 'Demo Location 1',
    tenant_id: 2309,
    type: '',
  },
  {
    city_id: 33,
    county_id: 1,
    id: 2323,
    lat: 22,
    location_type: 3,
    long: 33,
    name: 'Demo Location 2',
    tenant_id: 2309,
    type: '',
  },
];

import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import FruitsVegetablesCharts from './FruitsVegetablesCharts';
import FruitsVegetablesImages from './FruitsVegetablesImages';
import { useEffect } from 'react';
import useToolbarSetter from '../../../hooks/Common/useToolbarSetter';
import { RootState } from '../../../redux/store';
import { DEMO_ACCOUNTS } from '../../../web_api/constants';

const FruitsVegetablesInfo = () => {
  const { setToolbar } = useToolbarSetter();
  useEffect(() => {
    setToolbar('date-and-hour', `${activeLocation}`);
  });
  const navigate = useNavigate();
  const params = useParams();
  const locationId = params.locationId;
  const activeLocation = useSelector((state: RootState) => state.activeLocation.value);
  const userName = useSelector((state: RootState) => state.user.user_name);

  return (
    <>
      <div className='animated fadeInLeft position-absolute d-none d-xxl-block' style={{ top: '6%', left: '1.5%' }}>
        <i className='bi bi-shop-window bg-muted opacity-25' style={{ fontSize: '40.5rem' }}>
          <span
            className='bg-dark text-inverse-dark rounded-2 p-2 position-absolute fs-4x cursor-pointer'
            onClick={() => {
              navigate(-1);
            }}
            style={{ top: '6%', right: '20%' }}
          >
            <i className='bi bi-chevron-left fs-4x text-white' />
            {locationId}
          </span>
        </i>
      </div>

      <div
        className='my-4 ms-3 h-25 me-auto bg-dark text-inverse-dark rounded-2 p-2 fs-2x cursor-pointer d-xxl-none opacity-25 animated fadeInLeft'
        onClick={() => {
          navigate(-1);
        }}
      >
        <i className='bi bi-chevron-left fs-2x text-white' />
        {locationId}
      </div>

      <div className='container'>
        <div className='d-flex flex-column animated zoomIn' style={{ marginTop: `${window.innerWidth > 1399 ? '6.25rem' : ''}` }}>
          <FruitsVegetablesImages />
          <FruitsVegetablesCharts />
        </div>
      </div>
    </>
  );
};

export default FruitsVegetablesInfo;

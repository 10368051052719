import { useEffect, useState } from 'react';
import getLocations from '../../../web_api/methods/settings/locations/getLocations';
import { locationsList } from '../../../web_api/models';
import { ChartsWidget16 } from '../../../_metronic/widgets/charts/ChartsWidget16';
import { TablesWidget1 } from '../../../_metronic/widgets/tables/TablesWidget1';
import { useDispatch } from 'react-redux';
import { activeLocation, setValue } from '../../../redux/activeLocationReducer';
import useToolbarSetter from '../../../hooks/Common/useToolbarSetter';
import { Spinner } from 'react-bootstrap';
export default function Users() {
  const { setToolbar } = useToolbarSetter();
  useEffect(() => {
    setToolbar('users', `Users`);
  });
  const dispatch = useDispatch();
  const [locationsOnUser, setLocationsOnUser] = useState<locationsList>([]);
  const [isLoadingLocations, setIsLoadingLocations] = useState(false);
  useEffect(() => {
    loadLocations();
  }, []);
  const loadLocations = async () => {
    try {
      setIsLoadingLocations(true);

      let loadedLocations: locationsList = await getLocations().then((res) => {
        setIsLoadingLocations(false);
        setLocationsOnUser(res);
        return res;
      });
      console.log('loadedLocations', loadedLocations);
      if (loadedLocations.length === 1)
        dispatch(
          setValue({
            value: loadedLocations[0].name,
            id: loadedLocations[0].id.toString(),
          })
        );
    } catch (e) {
      console.log(e);
    }
  };
  return (
    <div className='container'>
      <div className='d-flex flex-column'>
        {/* Header */}
        <div className='d-flex flex-column col-sm-12 p-0'>
          <div className='d-flex flex-column w-sm-100 h-100 p-5'>
            <ChartsWidget16 className={''} locationsOnUser={locationsOnUser} isLoadingLocations={isLoadingLocations} />
          </div>
        </div>
        <div className={`d-flex flex-row col-sm-12 p-5 ${isLoadingLocations ? 'justify-content-center' : ''}`}>
          {isLoadingLocations ? (
            <Spinner animation='border' role='statusLocations'></Spinner>
          ) : (
            <TablesWidget1 className={'flex-fill'} locationsOnUserParam={locationsOnUser} />
          )}
          {/* <TablesWidget1 className={'flex-fill'} locationsOnUser={locationsOnUser} /> */}
        </div>
      </div>
    </div>
  );
}

import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import { locationsList, userLocationList } from '../../../web_api/models';
import getLocations from '../../../web_api/methods/settings/locations/getLocations';
import { LocationStatus } from './LocationStatus';
import { demoLocationsList } from '../../QueueManagement/demoAvgCashRegisterOpen';

export default function LocationsBakery() {
  const userList = useSelector((state: RootState) => state.userList);
  const selectedUser = useSelector((state: RootState) => state.selectedUser);
  const searchTextFilter = useSelector((state: RootState) => state.toolbarSearchFilter);
  const [locations, setLocations] = useState<locationsList>(demoLocationsList);
  const [loadLocationsFrom, setLoadLocationsFrom] = useState<locationsList | userLocationList>([]);
  const [filteredLocations, setFilteredLocations] = useState<locationsList | userLocationList>([]);
  const username = useSelector((state: RootState) => state.user.user_name);
  const [loading, setLoading] = useState(true);

  const isDemoUser = username.includes('demo');

  useEffect(() => {
    loadLocations();
  }, []);

  useEffect(() => {
    if (selectedUser.id === '0') {
      setLoadLocationsFrom(locations);
    } else setLoadLocationsFrom(userList.list.find((x) => x.id === selectedUser.id)?.locations!);
  }, [selectedUser]);

  const loadLocations = async () => {
    try {
      let loadedLocations: locationsList = isDemoUser ? demoLocationsList : await getLocations();
      setLocations(loadedLocations);
      setLoading(false);
      if (selectedUser.id === '0') {
        setLoadLocationsFrom(loadedLocations);
      } else setLoadLocationsFrom(userList.list.find((x) => x.id === selectedUser.id)?.locations!);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (searchTextFilter.searchText === '') {
      setFilteredLocations(loadLocationsFrom);
      return;
    }
    setFilteredLocations(loadLocationsFrom.filter((location) => location.name.toLowerCase().includes(searchTextFilter.searchText.toLowerCase())));
    console.log({ filteredLocations });
  }, [loadLocationsFrom, searchTextFilter]);

  return (
    <div className='row'>
      {!loading &&
        filteredLocations.map((item, index) => (
          <LocationStatus key={item.id} locationId={item.id} locationName={item.name} className='animated zoomIn' delay={`0.${index + 1}s`} />
        ))}
    </div>
  );
}

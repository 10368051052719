import callApiAxiosClient from '../../../axios/axios_client';
import { locationSchedule } from '../../../models';

async function addLocationSchedule({ end_time_d, end_time_ls, location_id, start_time_d, start_time_ls }: locationSchedule) {
  const token = JSON.parse(localStorage.getItem('user')!);
  const authorization = 'Bearer ' + token.token;
  return callApiAxiosClient({
    method: 'post',
    url: 'settings/locations/schedule',
    headers: {
      'Content-Type': 'Application/json',
      Authorization: authorization,
    },
    params: {
      location_id: location_id,
      start_time_ls: start_time_ls,
      end_time_ls: end_time_ls,
      start_time_d: start_time_d,
      end_time_d: end_time_d,
    },
  });
}
export default addLocationSchedule;

import { Icon } from 'leaflet';
import 'leaflet/dist/leaflet.css';
import React, { useState, useCallback, useEffect } from 'react';
import { FeatureGroup, ImageOverlay, MapContainer, Marker, Polyline, Popup, SVGOverlay, TileLayer, Tooltip, useMapEvents } from 'react-leaflet';
import MarkerClusterGroup from 'react-leaflet-cluster';
import finnishFlag from './finish_flag.svg';
import { Link } from 'react-router-dom';
import staticMap from '../../assets/maps/ENEC.png';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';

type Props = {
  className: string;
  allowMultipleMarkers?: boolean;
  allowDelete?: boolean;
  allowAdd?: boolean;
  currentMarker?: { id: number; position: { lat: number; long: number }; name: string }[];
  markers?: { id: number; position: { lat: number; long: number }; name: string; info?: string }[];
  lines?: { id: number; positionStart: { lat: number; long: number }; positionEnd: { lat: number; long: number }; color: string }[];
  data?: { name: string; lat: number; long: number };
  onMarkerAddedOrChanged?: (name: string, lat: number, long: number) => void;
  draggableMarkers?: boolean;
  highlightMarkerId?: number;
  isLink?: boolean;
  zoom?: number;
  center?: [number, number];
  requireName?: boolean;
};

const LeafMap: React.FC<Props> = ({
  className,
  allowMultipleMarkers,
  allowDelete,
  allowAdd,
  data,
  currentMarker,
  markers = [],
  onMarkerAddedOrChanged,
  lines,
  draggableMarkers = true,
  highlightMarkerId,
  isLink = false,
  zoom = process.env.REACT_APP_MAP !== '' ? 15 : 7,
  center = [46, 25],
  requireName = true,
}) => {
  const icon = new Icon({
    iconUrl: 'https://cdn-icons-png.flaticon.com/128/684/684908.png',
    iconSize: [38, 38],
    iconAnchor: [19, 38],
  });

  const biggerIcon = new Icon({
    iconUrl: 'https://cdn-icons-png.flaticon.com/128/684/684908.png',
    iconSize: [50, 50],
    iconAnchor: [25, 49],
  });
  const [addedMarkersCount, setAddedMarkersCount] = useState(0);

  const showCluster = useSelector((state: RootState) => state.cluster.cluster);

  const [markersState, setMarkers] = useState(markers!.filter((x) => x.position.lat !== 0 && x.position.long !== 0));

  const bounds = [
    [24.4092, 54.5945],
    [24.4464, 54.6458],
  ];

  // calculate center of the map based on the bounds
  const centerMap: [number, number] = [(bounds[0][0] + bounds[1][0]) / 2, (bounds[0][1] + bounds[1][1]) / 2];

  const handleDragEnd = useCallback(
    (e: any, id: any) => {
      const { lat, lng } = e.target.getLatLng();
      const name = markersState.find((x) => x.id === id)?.name;
      setMarkers((prevMarkers) => prevMarkers.map((marker) => (marker.id === id ? { ...marker, position: { lat: lat, long: lng } } : marker)));

      if (onMarkerAddedOrChanged) onMarkerAddedOrChanged(name!, lat, lng);
    },
    [markersState]
  );

  const handleDeleteMarker = (markerId: number, event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();

    setMarkers((prevMarkers) => prevMarkers.filter((marker) => marker.id !== markerId));
    if (!allowMultipleMarkers) {
      setAddedMarkersCount((prevCount) => prevCount - 1);
    }
  };
  useEffect(() => {
    setMarkers(markers!.filter((x) => x.position.lat !== 0 && x.position.long !== 0));
  }, [markers]);

  const AddMarkerComponent = () => {
    const map = useMapEvents({
      click: (e) => {
        if ((!allowMultipleMarkers && addedMarkersCount >= 1) || (markersState.length >= 1 && !allowMultipleMarkers) || !allowAdd) {
          return;
        }

        const newMarker = {
          id: Math.random(),
          position: { lat: e.latlng.lat, long: e.latlng.lng },
          name: requireName ? data?.name || prompt('Enter marker name:') || 'Unnamed' : 'Unnamed',
        };

        setMarkers((currentMarkers) => {
          return [...currentMarkers, newMarker];
        });
        setAddedMarkersCount((prevCount) => prevCount + 1);
        if (onMarkerAddedOrChanged) {
          onMarkerAddedOrChanged(newMarker.name, newMarker.position.lat, newMarker.position.long);
        }
      },
    });
    return null;
  };

  useEffect(() => {
    console.log('env', process.env.REACT_APP_MAP);
    // Check if there are any markers
    if (data === undefined) return;
    if (data.lat === undefined || data.long === undefined) return;
    if (markersState.length > 0) {
      // Update the position of the existing marker
      const updatedMarker = {
        ...markersState[0],
        position: { lat: data.lat!, long: data.long! },
      };
      setMarkers([updatedMarker]);
    } else if (data.lat && data.long && data.lat !== 0 && data.long !== 0) {
      // If no markers and both lat and long are provided, add a new marker
      const newMarker = {
        id: Math.random(),
        position: { lat: data.lat, long: data.long },
        name: data?.name || 'Unnamed',
      };
      setMarkers([newMarker]);
    }
  }, [data]);

  return (
    <MapContainer center={process.env.REACT_APP_MAP !== '' ? centerMap : center} zoom={zoom} className={`${className}`} maxZoom={18}>
      {process.env.REACT_APP_MAP !== '' ? (
        <ImageOverlay
          url={staticMap}
          bounds={[
            [24.4092, 54.5945],
            [24.4464, 54.6458],
          ]}
          interactive
        />
      ) : (
        <TileLayer url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png' />
      )}
      {showCluster ? (
        <MarkerClusterGroup>
          {markersState.map((marker) => {
            return (
              !isNaN(marker.position.lat) &&
              !isNaN(marker.position.long) && (
                <Marker
                  key={marker.id}
                  icon={marker.id === highlightMarkerId ? biggerIcon : icon}
                  // icon={biggerIcon}
                  position={[marker.position.lat, marker.position.long]}
                  draggable={draggableMarkers}
                  eventHandlers={{
                    dragend: (e) => handleDragEnd(e, marker.id),
                  }}
                >
                  <Popup>
                    <span className='d-flex flex-center'>
                      {!isLink ? (
                        marker.name
                      ) : (
                        <Link to={`/traffic/dashboard/${marker.id}`} className='d-flex flex-row align-items-center gap-2'>
                          {marker.name} <i className='bi bi-arrow-right text-primary' />
                        </Link>
                      )}
                      {allowDelete && (
                        <button
                          type='button'
                          className={`btn btn-sm btn-icon btn-color-primary btn-active-white border-0 me-n3`}
                          data-kt-menu-trigger='click'
                          data-kt-menu-placement='bottom-end'
                          data-kt-menu-flip='top-end'
                          onClick={(event) => handleDeleteMarker(marker.id, event)}
                          style={{ marginLeft: '10px', cursor: 'pointer' }}
                        >
                          <i className='svg-icon-2 bi bi-trash fa-3x' />
                        </button>
                      )}
                    </span>
                  </Popup>
                  {highlightMarkerId && marker.id === highlightMarkerId && (
                    <Tooltip offset={[0, 0]} opacity={1} permanent className='bg-primary'>
                      <div className='text-weight-normal text-light fs-8'>{marker.info}</div>
                    </Tooltip>
                  )}
                </Marker>
              )
            );
          })}
        </MarkerClusterGroup>
      ) : (
        <>
          {markersState.map((marker) => {
            return (
              !isNaN(marker.position.lat) &&
              !isNaN(marker.position.long) && (
                <Marker
                  key={marker.id}
                  icon={marker.id === highlightMarkerId ? biggerIcon : icon}
                  // icon={biggerIcon}
                  position={[marker.position.lat, marker.position.long]}
                  draggable={draggableMarkers}
                  eventHandlers={{
                    dragend: (e) => handleDragEnd(e, marker.id),
                  }}
                >
                  <Popup>
                    <span className='d-flex flex-center'>
                      {!isLink ? (
                        `${marker.name}`
                      ) : (
                        <Link to={`/traffic/dashboard/${marker.id}`} className='d-flex flex-row align-items-center gap-2'>
                          {marker.name} <i className='bi bi-arrow-right text-primary' />
                        </Link>
                      )}
                      {allowDelete && (
                        <button
                          type='button'
                          className={`btn btn-sm btn-icon btn-color-primary btn-active-white border-0 me-n3`}
                          data-kt-menu-trigger='click'
                          data-kt-menu-placement='bottom-end'
                          data-kt-menu-flip='top-end'
                          onClick={(event) => handleDeleteMarker(marker.id, event)}
                          style={{ marginLeft: '10px', cursor: 'pointer' }}
                        >
                          <i className='svg-icon-2 bi bi-trash fa-3x' />
                        </button>
                      )}
                    </span>
                  </Popup>
                  {highlightMarkerId && marker.id === highlightMarkerId && (
                    <Tooltip offset={[0, 0]} opacity={1} permanent className='bg-primary'>
                      <div className='text-weight-normal text-light fs-8'>{marker.info}</div>
                    </Tooltip>
                  )}
                </Marker>
              )
            );
          })}
        </>
      )}

      {lines && lines.length > 0 && (
        <FeatureGroup>
          {lines?.map((line) => {
            return (
              <Polyline
                key={line.id}
                positions={[
                  [line.positionStart.lat, line.positionStart.long],
                  [line.positionEnd.lat, line.positionEnd.long],
                ]}
                pathOptions={{ color: line.color }}
              />
            );
          })}
          <SVGOverlay
            bounds={[
              [lines?.at(lines.length - 1)?.positionEnd.lat!, lines?.at(lines.length - 1)?.positionEnd.long!],
              [lines?.at(lines.length - 1)?.positionEnd.lat! + 0.8, lines?.at(lines.length - 1)?.positionEnd.long! + 1],
            ]}
            attributes={{ stroke: 'red' }}
          >
            <image x='40%' y='90%' width='50' height='50' href={finnishFlag} transform='translate(-25, -25)' />
          </SVGOverlay>
        </FeatureGroup>
      )}
      <AddMarkerComponent />
    </MapContainer>
  );
};
export { LeafMap };
